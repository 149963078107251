<template>
    <NavBar></NavBar>
    <div class="container" style="overflow-x: auto">
        <router-view></router-view>
    </div>
</template>


<script>
import { commonMixin } from '@/js/common'
import NavBar from './Dashboard/NavBar.vue'

export default {
    name: "app-dashboard",
    data() {
        return {
            appName: process.env.VUE_APP_NAME,
            items: {}
        };
    },
    mounted() {
        if (localStorage.getItem("user-access-token") == null) {
            this.$router.push({ name: "Login" });
        }
    },
    methods: {
        async logout() {
            var data = await commonMixin.methods.callPost("user/logout", "");
            if (data.status == 0) {
                commonMixin.methods.showToast(data.message);
            }
            localStorage.clear();
            this.$router.push({ name: "Login" });
        }
    },
    components: { NavBar }
}
</script>