<template>
    
    <div>
        <br />
        <h1 class="display-6 left">{{ heading }}</h1>
        <br />
        <div class="container">
            <div class="row mb-3" style="border-bottom: 1px solid grey;">

                <div class="col-sm-6 left">
                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label left">Customer: </label>
                        <div class="col-sm-9">

                            <div class="dropdown left">
                                <button class="btn left" type="button" data-bs-toggle="dropdown" style="width: 100%" @click="$refs.searchBox.focus()">
                                    <span v-if="sale.cust == null">
                                        Select customer
                                    </span>

                                    <span v-if="sale.cust != null">
                                        {{ sale.cust.name }}
                                    </span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" style="width: 100%">
                                    <li> <input type="text" ref="searchBox" class="form-control" v-on:keyup="searchCustChanged()" 
                                        v-model="custSearchBOx" onfocus="this.select();"/> </li>
                                    <li v-for="cust in searchFilteredCust" v-bind:key="cust.id" @click="custChanged(cust)">
                                        <div class="dropdown-item">
                                            {{ cust.name }}
                                            <span style="float: right"
                                                :class="cust.balance.balance < 0 ? 'red' : 'green'">
                                                ₹ {{ cust.balance.balance }}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="form-label left">Detail: </label>
                        <textarea class="form-control" v-model="sale.address" rows="4"> </textarea>
                    </div>
                    <div class="row mb-3">

                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-3 col-form-label left">GSTIN: </label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="sale.gstin">
                        </div>
                    </div>

                </div>


                <div class="col-sm-6">
                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label left">Balanace: </label>
                        <label class="col-sm-6 col-form-label left" v-if="sale.cust != null"
                            :class="sale.cust.balance.balance < 0 ? 'red' : 'green'">
                            <b>₹ {{ parseFloat(sale.cust.balance.balance).toFixed(2) }}</b>
                        </label>
                        <label class="col-sm-6 col-form-label left" v-if="sale.cust == null">
                            Select customer to view balance
                        </label>
                    </div>

                    <div class="row mb-3">
                        <label class="col-sm-2 col-form-label left">Date: </label>
                        <div class="col-sm-6">
                            <input type="date" class="form-control" v-model="sale.bill_date" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="form-label left">Remark: </label>
                        <div class="col-sm-8">
                            <textarea class="form-control" v-model="sale.remark" />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-sm-6 left">
                            <input class="form-check-input" @change="taxIncludedChanged()"
                                v-model="sale.rate_including_tax" type="checkbox">
                            <label class="form-check-label" for="inlineCheckbox1" style="margin-left: 0.5em;"> Tax
                                included</label>
                        </div>
                    </div>

                </div>
            </div>


            <div class="row mb-3">
                <div class="col-sm-3 left">
                    <label class="form-label">Item: </label>
                    <select ref="newItem" class="form-select" v-model="newItem.id" @change="itemChanged()"
                        @keydown.enter="addItem()">
                        <option v-for="item in filteredItems" v-bind:key="item.id" v-bind:value="item.id">{{ item.name
                        }} -
                            {{ item.weight_in_grms }}g
                            <span v-if="item.package_type != null">({{ item.package_type }})</span>

                        </option>
                    </select>
                </div>

                <div class="col-sm-1 left">
                    <label class="form-label">Qty: </label>
                    <input type="text" class="form-control" @keydown.enter="addItem()" v-model="newItem.qty">
                </div>

                <div class="col-sm-2 left">
                    <label class="form-label">Rate: </label>
                    <input type="text" class="form-control" @keydown.enter="addItem()" v-model="newItem.rate">
                </div>

                <div class="col-sm-1 left">
                    <label class="form-label">Tax %: </label>
                    <select class="form-select" v-model="newItem.tax_percentage" @keydown.enter="addItem()">
                        <option value="0">0%</option>
                        <option value="3">3%</option>
                        <option value="5">5%</option>
                        <option value="12">12%</option>
                        <option value="18">18%</option>
                        <option value="28">28%</option>
                    </select>
                </div>

                <div class="col-sm-2 left">
                    <label class="form-label">Comment: </label>
                    <input type="text" class="form-control" @keydown.enter="addItem()" v-model="newItem.comment">
                </div>

                <div class="col left mt-2">
                    <button type="button" class="btn me-2 mb-2" :class="isEdit ? 'btn-warning' : 'btn-primary'"
                        @click="addItem()"><i class="bi bi-plus-square-dotted"></i> {{ isEdit ? "Update" :
                                "Add"
                        }}</button>
                    <button type="button" class="btn mb-2 btn-secondary" @click="resetItem()"><i
                            class="bi bi-arrow-clockwise"></i> Reset</button>

                </div>

            </div>

            <div class="row mb-3" v-if="sale.items.length > 0">
                <table class="table table-hover text-nowrap pointer">
                    <thead class="thead-dark table-dark saleThead">
                        <tr>
                            <th class="fitContent">Action</th>
                            <th class="fitContent left">Sr. No.</th>
                            <th class="left">Description</th>
                            <th class="fitContent">Qty</th>
                            <th class="fitContent">Rate</th>
                            <th class="fitContent">Taxable<br />Amount</th>
                            <th class="fitContent">GST</th>
                            <th class="fitContent">Total</th>
                        </tr>
                    </thead>
                    <tbody style="border: 1px black solid;">
                        <tr class="billItemRow" v-for="(item, index) in sale.items" v-bind:key="item.id">
                            <td style="border-right: 1px solid black">
                                <button type="button" title="Update item" class="btn btn-light" @click="editItem(item)"
                                    v-if="item.item == null || item.item.deleted_at == null"
                                    style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>
                                <button type="button" title="Remove item" class="btn btn-light"
                                    @click="deleteItem(item)"><i class="bi bi-trash link-danger"></i></button>
                            </td>
                            <td>{{ index + 1 }}</td>
                            <td class="left"><span>{{ item.detail.name }}</span>
                                <p class="text-muted" style="font-size: smaller">{{ item.comment }}</p>
                            </td>
                            <td class="right">{{ item.qty }}</td>
                            <td class="right">{{ item.detail.rate }}</td>
                            <td class="right">{{ item.detail.taxableAmount }}</td>
                            <td class="right">{{ item.detail.gst }} <p class="text-muted" style="font-size: smaller">
                                    {{ item.tax_percentage }}%</p>
                            </td>
                            <td class="right">{{ item.detail.amount }}</td>
                        </tr>
                    </tbody>
                    <thead class="thead-dark table-dark saleThead">
                        <tr>
                            <th colspan="3" class="right">Total</th>
                            <th>{{ totalQty }}</th>
                            <th></th>
                            <th></th>
                            <th>{{ totalTax }}</th>
                            <th>{{ parseFloat(sale.total - this.sale.adjustment).toFixed(2) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="6" class="right">Total amount before tax:</td>
                            <th colspan="2" class="right">{{ this.totalTaxable }}</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="right">Total tax:</td>
                            <th colspan="2" class="right">{{ this.totalTax }}</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="right">Adjustment:</td>
                            <th colspan="2" class="right">{{ this.sale.adjustment }}</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="right">Total amount:</td>
                            <th colspan="2" class="right">{{ this.sale.total }}</th>
                        </tr>
                    </tbody>
                </table>
                <div class="container" v-if="sale.cust != null">
                    <div class="row">
                        <label class="col-sm-12 col-form-label left">New balance: <b>{{
                                parseFloat(parseFloat(sale.cust.balance.balance) +
                                    parseFloat(sale.total)).toFixed(2)
                        }}</b>
                        </label>

                    </div>
                </div>


            </div>


        </div>


        <button type="button" class="btn btn-link text-danger" @click.prevent="cancel()">Cancel</button>
        <button type="submit" class="btn btn-primary" @click.prevent="addBill()">Submit</button>

    </div>
</template>

<script>

import { commonMixin } from '@/js/common';

export default {

    name: 'app-sale-add-update',
    data() {
        return {
            items: null,
            filteredItems: [],
            filteredCust: [],
            searchFilteredCust: [],
            custSearchBOx: "",
            custs: null,
            sale: {
                bill_date: commonMixin.methods.getJustDate(new Date()), items: [], "discount": 0, "total": 0,
                rate_including_tax: true, "adjustment": 0
            },
            newItem: {},
            heading: "",
            isEdit: false,
            totalTax: 0,
            totalQty: 0,
            totalTaxable: 0,
            originalEntityId: null,
        }
    },
    methods: {
        searchCustChanged() {
            this.searchFilteredCust = this.filteredCust;
            console.log("hi")
            if(this.custSearchBOx == "") {
                return;
            }
            this.searchFilteredCust = [];
            for (var i = 0; i < this.filteredCust.length; i++) {
                if (this.filteredCust[i].name.toLowerCase().includes(this.custSearchBOx.toLowerCase())) {
                    this.searchFilteredCust.push(this.filteredCust[i]);
                }
            }
        },
        custChanged(newCust) {
            this.sale.cust = newCust;
            this.sale.entity_id = newCust.id;

            if (this.heading != "Add") {
                return;
            }

            this.sale.gstin = newCust.GSTIN;
            this.sale.address = newCust.address == null ? "" : newCust.address;
            if (newCust.city != null) {
                this.sale.address += ",\n" + newCust.city
                if (newCust.pin != null) {
                    this.sale.address += " - " + newCust.pin;
                }
            }

            if (newCust.state != null) {
                this.sale.address += ",\n" + newCust.state;
            }

            if (newCust.phone != null) {
                this.sale.address += "\n\nPhone: " + newCust.phone;
            }

            if (newCust.email != null) {
                this.sale.address += "\nEmail: " + newCust.email;
            }

        },
        cancel() {
            this.$router.back();
        },
        itemChanged() {
            let obj = this.items.find(i => i.id == this.newItem.id);
            this.newItem.qty = 1;
            this.newItem.rate = obj.rate;
            this.newItem.tax_percentage = 18;
            this.newItem.comment = "";
        },
        addItem() {
            if (this.newItem.id == null) {
                alert("Item name can't be empty.");
                return;
            }
            if (isNaN(this.newItem.qty) || this.newItem.qty == "" || this.newItem.qty < 1) {
                alert("Enter valid quentity");
                return;
            }
            if (isNaN(this.newItem.rate) || this.newItem.rate == "" || this.newItem.rate <= 0) {
                alert("Enter valid rate");
                return;
            }
            this.newItem.item_id = this.newItem.id;
            this.addItemDetails(this.newItem);
            if (this.isEdit) {
                var index = this.sale.items.indexOf(this.newItem);
                this.sale.items[index] = this.newItem;
            } else {
                this.sale.items.push(this.newItem);
            }

            this.$refs.newItem.focus();
            this.newItem = {};
            this.isEdit = false;
            this.calculateTotal();
        },
        resetItem() {
            this.$refs.newItem.focus();
            this.newItem = {};
            this.isEdit = false;
        },
        editItem(item) {
            this.$refs.newItem.focus();
            this.newItem = item;
            this.isEdit = true;
        },
        deleteItem(item) {
            if (confirm("Are you sure you want to remove this item?")) {
                var index = this.sale.items.indexOf(item);
                this.sale.items.splice(index, 1);
            }
            if (this.sale.deletedItems && item.bill_item_id) {
                this.sale.deletedItems.push(item.bill_item_id);
            }
            this.calculateTotal();
        },
        addItemDetails(item) {
            //Create display name
            item.detail = {}
            var obj = this.items.find(i => i.id == item.id);
            var name = obj.name + " - " + obj.weight_in_grms + "g ";
            name += obj.package_type == null ? "" : "(" + obj.package_type + ")"
            item.detail.name = name;

            //Calc amount
            if (this.sale.rate_including_tax) {
                item.detail.rate = (item.rate * 100) / (100 + parseFloat(item.tax_percentage));
            } else {
                item.detail.rate = item.rate;
            }
            item.detail.taxableAmount = item.detail.rate * item.qty;
            item.detail.gst = (item.tax_percentage * item.detail.taxableAmount) / 100;
            item.detail.amount = parseFloat(item.detail.gst) + parseFloat(item.detail.taxableAmount);

            item.detail.rate = parseFloat(item.detail.rate).toFixed(2);
            item.detail.taxableAmount = parseFloat(item.detail.taxableAmount).toFixed(2);
            item.detail.gst = parseFloat(item.detail.gst).toFixed(2);
            item.detail.amount = parseFloat(item.detail.amount).toFixed(2);
        },
        calculateTotal() {
            this.sale.total = 0;
            this.totalTax = 0;
            this.totalQty = 0;
            this.totalTaxable = 0;
            for (var i = 0; i < this.sale.items.length; i++) {
                this.sale.total = parseFloat(this.sale.total) + parseFloat(this.sale.items[i].detail.amount);
                this.totalTax = parseFloat(this.totalTax) + parseFloat(this.sale.items[i].detail.gst);
                this.totalTaxable = parseFloat(this.totalTaxable) + parseFloat(this.sale.items[i].detail.taxableAmount);
                this.totalQty = parseFloat(this.sale.items[i].qty) + parseFloat(this.totalQty);
            }
            this.sale.total = parseFloat(this.sale.total).toFixed(2);
            this.totalTax = parseFloat(this.totalTax).toFixed(2);
            this.totalTaxable = parseFloat(this.totalTaxable).toFixed(2);
            this.sale.adjustment = parseFloat(Math.round(this.sale.total) - this.sale.total).toFixed(2);
            this.sale.total = parseFloat(Math.round(this.sale.total)).toFixed(2);

        },
        taxIncludedChanged() {
            for (var i = 0; i < this.sale.items.length; i++) {
                this.addItemDetails(this.sale.items[i]);
            }
            this.calculateTotal();
        },
        async addBill() {
            var d = new Date(this.sale.bill_date);
            if (!(d instanceof Date) || isNaN(d)) {
                alert("Select valid bill date");
                return;
            }
            if (this.sale.cust == null) {
                alert("Select customer name");
                return;
            }
            if (this.sale.items == null || this.sale.items.length == 0) {
                alert("Add atlease one item.");
                return;
            }

            if (this.heading != "Add") {
                if (this.originalEntityId != this.sale.cust.id) {
                    if (!confirm("Are you sure you want to change Customer? This will remove all the payment links associated with this bill.")) {
                        return;
                    } else {
                        this.sale.removeAllPayments = true;
                    }
                }
                this.sale.received_amount = 0;
                for (var i = 0; i < this.sale.payment_links.length; i++) {
                    this.sale.received_amount = parseFloat(this.sale.payment_links[i].received_amount) + parseFloat(this.sale.received_amount);
                }
                if (this.sale.received_amount > this.sale.total) {
                    if (confirm("Amount of payments linked to this bill is more than actual bill amount. Would you like to continue?")) {
                        if (confirm("This is remove all the payment links associated with this bill. Still you would like to continue?")) {
                            this.sale.removeAllPayments = true;
                        } else {
                            return;
                        }
                    } else {
                        return;
                    }
                }
            }

            var data;
            if (this.$route.params.id) {
                data = await commonMixin.methods.callPost('sale/' + this.$route.params.id, this.sale);
                commonMixin.methods.showToast(data.message);
            } else {
                data = await commonMixin.methods.callPost('sale', this.sale);
                commonMixin.methods.showToast(data.message);
            }
            if (data.status != "0") {
                return;
            }
            this.$router.push({ name: 'SaleMain' });
        }
    },
    async mounted() {
        if (this.$route.params.id) {
            this.heading = "Update";
            var data = await commonMixin.methods.callGet("sale/" + this.$route.params.id, "");
            this.items = await (await commonMixin.methods.callGet("products/all", "")).obj;
            this.filteredItems = [];
            for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].deleted_at == null) {
                    this.filteredItems.push(this.items[i]);
                }
            }
            this.custs = await (await commonMixin.methods.callGet("entity/getAllEntites", "")).obj;
            this.filteredCust = [];
            for (i = 0; i < this.custs.length; i++) {
                if (this.custs[i].deleted_at == null || this.custs[i].id == data.obj.entity_id) {
                    this.filteredCust.push(this.custs[i]);
                }
            }
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.$router.push({ name: 'SaleMain' });
                return;
            }
            this.sale = data.obj;
            this.sale.rate_including_tax = this.sale.rate_including_tax == 1;
            this.originalEntityId = this.sale.entity_id;
            this.sale.deletedItems = [];

            for (i = 0; i < this.sale.items.length; i++) {
                this.sale.items[i].bill_item_id = this.sale.items[i].id;
                this.sale.items[i].id = this.sale.items[i].item_id;
                //this.sale.items[i].item = null;
            }
            this.custChanged(this.custs.find(i => i.id == this.sale.entity_id));
            this.taxIncludedChanged();
        } else {
            this.items = await (await commonMixin.methods.callGet("products", "")).obj;
            this.custs = await (await commonMixin.methods.callGet("entity/getEntites", "")).obj;
            this.filteredItems = this.items;
            this.filteredCust = this.custs;
            this.heading = "Add";
        }
        this.searchCustChanged()
    }
}

</script>

<style>
.billItemRow {
    border-bottom: 1px solid lightskyblue;
}

.billItemRow td {
    border-left: 1px solid black;
    padding-bottom: 0px;
}

.fitContent {
    white-space: nowrap;
    width: 1%;
}
</style>