<template>
    <br>
    <br>
    <h1>Customer</h1>

    <div style="text-align: right; margin-bottom: 0.2em;">
        <button type="button" title="Add new" class="btn btn-light" @click="exportCSV()">
            <span class="bi bi-arrow-bar-up link-success"> Export</span></button>
       
        <button type="button" title="Add new" class="btn btn-light" @click="addNew()">
            <span class="bi bi-plus-square link-success"> Add New</span></button>
    </div>
    <div class="" style="overflow-x: auto">
        <table class="table table-striped table-hover text-nowrap pointer">
            <thead class="table-dark">
                <tr>
                    <th>ID</th>
                    <th class="left">Name</th>
                    <th class="left">Contact Person</th>
                    <th class="left">Mobile</th>
                    <th class="right">Balance</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="custs.length == 0">
                    <td colspan="6">No data found.</td>
                </tr>
                <tr v-for="cust in custs" v-bind:key="cust.id" @click="getInfo(cust.id)">
                    <th scope="row">{{cust.id}}</th>
                    <td class="left">{{cust.name}}</td>
                    <td class="left">{{cust.contact_person}}</td>
                    <td class="left">{{cust.mobile}}</td>
                    <td class="right"><b>
                            <p v-if="cust.balance != null" :class="cust.balance.balance < 0 ? 'red' : 'green'">
                                ₹ {{ parseFloat(cust.balance.balance).toFixed(2)}}
                            </p>
                        </b>
                    </td>
                    <td @click.stop>
                        <button type="button" title="Update customer" class="btn btn-light" @click="editObj(cust.id)"
                            style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>
                        <button type="button" title="Remove customer" class="btn btn-light"
                            @click="deleteObj(cust.id)"><i class="bi bi-trash link-danger"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-cust-main',
    data() {
        return {
            custs: [],
        }
    },
    methods: {
        getInfo(id) {
            for (var i = 0; i < this.custs.length; i++) {
                if (this.custs[i].id == id) {
                    this.$router.push({ name: 'CustomerInfo', params: { "id": id} })
                    break;
                }
            }
        },
        addNew() {
            this.$router.push({ name: 'CustomerAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'CustomerUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('entity/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.custs = await (await commonMixin.methods.callGet("entity/getEntites", "")).obj;
            }
        },
        exportCSV() {
            var rows = [
                ["id", "name", "balance", "contact person", "mobile", "phone", "email", "website",
                "address", "city", "pin", "state",
                "GSTIN", "PAN", "Bank account number", "Bank IFSC", "Bank branch",
                "created_at","updated_at"]
            ];
            for(var i=0; i<this.custs.length; i++) {
                var obj = this.custs[i];
                var temp = [];
                temp.push(obj.id);
                temp.push(obj.name);
                temp.push(parseFloat(obj.balance.balance).toFixed(2));
                temp.push(obj.contact_person);
                temp.push(obj.mobile);
                temp.push(obj.phone);
                temp.push(obj.email);
                temp.push(obj.website);
                temp.push(obj.address);
                temp.push(obj.city);
                temp.push(obj.pin);
                temp.push(obj.state);
                temp.push(obj.GSTIN);
                temp.push(obj.pan);
                temp.push(obj.bank_account_no);
                temp.push(obj.bank_ifsc);
                temp.push(obj.branch);
                temp.push(obj.created_at);
                temp.push(obj.updated_at);
                rows.push(temp);
            }
            console.log(rows);
            commonMixin.methods.createCSV(rows, "Customers.csv");
        }
    },
    async mounted() {
        this.custs = await (await commonMixin.methods.callGet("entity/getEntites", "")).obj;
    }
}
</script>
    
<style>
.left {
    text-align: left;
}

.pointer {
    cursor: pointer;
}

tr a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    color: inherit;
    text-decoration: inherit;
}

.row {
    margin-bottom: 2em;
}
</style>