<template>
    <div>
        <div class="left" style="margin-top: 0.2em;">
            <div class="row">
                <div class="col-4">
                    <button type="button" class="btn btn-link" @click="goBack()"><span
                            class="bi bi-arrow-left-circle" /> Go back</button>
                </div>
                <div class="col-8" style="text-align: right;">

                    <button type="button" title="Add new" class="btn btn-light" @click="addNew()"
                        style="margin-right: 0.2em;"><i class="bi bi-plus-square link-success"></i></button>

                    <button type="button" title="Update" class="btn btn-light" @click="editObj(objId)"
                        style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>

                    <button type="button" title="Remove" class="btn btn-light" @click="deleteObj(objId)"><i
                            class="bi bi-trash link-danger"></i></button>
                </div>
            </div>
        </div>
        <h1 class="display-6 left">{{heading}}</h1>

        <br>

        <div class="left info">
            <table class="table table-striped">
                <tbody>

                    <tr v-for="k in Object.keys(obj)" v-bind:key="k">
                        <th>
                            {{k}}:
                        </th>
                        <td>
                            {{obj[k]}}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>


<script>
// import { commonMixin } from '@/js/common';

export default {
    name: 'app-item-info',
    data() {
        return {

        }
    },
    methods: {
        goBack() {
            this.$router.back();
        },
    },
    props: {
        obj: {
            type: Object
        },
        objId: Number,
        heading: String,
        addNew: Function,
        editObj: Function,
        deleteObj: Function
    },

}
</script>