<template>
    <CommonInfo :objId="item.id" :heading="item.name" :obj="obj" :addNew="addNew" :editObj="editObj"
        :deleteObj="deleteObj"></CommonInfo>
</template>

<script>
import { commonMixin } from '@/js/common';
import CommonInfo from '../Common/CommonInfo.vue';

export default {
    name: 'app-item-info',
    data() {
        return {
            item: {},
            obj: {},
        }
    },
    components: {
        CommonInfo
    },
    methods: {

        addNew() {
            this.$router.push({ name: 'ItemAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'ItemUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('products/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.$router.push({ name: 'ItemMain' });
            }
        }
    },
    async mounted() {
        if (this.$route.params.obj) {
            this.item = JSON.parse(this.$route.params.obj);
        } else {
            var data = await commonMixin.methods.callGet("products/" + this.$route.params.id, "");
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.showInfo = false;
                return;
            }
            this.item = data.obj;
        }

        this.showInfo = true;

        this.obj["ID"] = this.item.id;
        this.obj["Name"] = this.item.name;
        this.obj["Package type"] = this.item.package_type;
        this.obj["Weight (in gm)"] = this.item.weight_in_grms;
        this.obj["Rate"] = this.item.rate;
        this.obj["MRP"] = this.item.mrp;
        this.obj["Barcode"] = this.item.barcode;
        this.obj["note"] = this.item.note;
        this.obj["Created"] = commonMixin.methods.getConvertedDate(this.item.created_at);
        this.obj["Last updated"] = commonMixin.methods.getConvertedDate(this.item.updated_at);
    }
}
</script>
