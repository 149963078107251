<template>
    <div>
        <br />
        <h1 class="display-6 left">{{heading}}</h1>
        <br />
        <form>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">Name</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.name">
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">Package type</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.package_type">
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">Weight in grams</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.weight_in_grms">
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">Rate</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.rate">
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">MRP</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.mrp">
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">Barcode</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.barcode">
                </div>
            </div>

            <div class="row mb-3">
                <label class="col-sm-3 col-form-label left">Note</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="item.note">
                </div>
            </div>

            <button type="button" class="btn btn-link text-danger" @click.prevent="cancel()">Cancel</button>            
            <button type="submit" class="btn btn-primary" @click.prevent="addItem()">Submit</button>
        </form>
    </div>
</template>

<script>

import { commonMixin } from '@/js/common';

export default {
    name: 'app-item-add-update',
    data() {
        return {
            item: { weight_in_grms: 0, rate: 0, mrp: 0 },
            heading: ""
        }
    },
    methods: {
        cancel() {
            this.$router.back();
        },
        async addItem() {
            if (this.item.name == "" || this.item.name == null) {
                alert("Item name can't be empty.");
                return;
            }
            if (isNaN(this.item.weight_in_grms)) {
                alert("Enter valid weight");
                return;
            }
            if (isNaN(this.item.rate)) {
                alert("Enter valid rate");
                return;
            }
            if (isNaN(this.item.mrp)) {
                alert("Enter valid MRP");
                return;
            }
            var data;
            if (this.$route.params.id) {
                data = await commonMixin.methods.callPost('products/' + this.$route.params.id, this.item);
                commonMixin.methods.showToast(data.message);
            } else {
                data = await commonMixin.methods.callPost('products', this.item);
                commonMixin.methods.showToast(data.message);
            }
            if (data.status != "0") {
                return;
            }
            this.$router.push({ name: 'ItemMain' });
        }
    },
    async mounted() {
        if (this.$route.params.id) {
            this.heading = "Update";
            var data = await commonMixin.methods.callGet("products/" + this.$route.params.id, "");
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.$router.push({ name: 'ItemMain' });
                return;
            }
            this.item = data.obj;
        } else {
            this.heading = "Add";
        }
    }
}
</script>