<template>
    <br>
    <h1>User</h1>
    <br>
    <div v-if="showInfo">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" :class="[this.currentNav == 'changePassword' ? 'active' : '']" aria-current="page"
                    @click="this.currentNav = 'changePassword'">Change Password</a>
            </li>

            <li class="nav-item" v-if="user.isAdmin">
                <a class="nav-link" :class="[this.currentNav == 'manageUsers' ? 'active' : '']" aria-current="page"
                    @click="manageUsers()">Manage Users</a>
            </li>

            <li class="nav-item" v-if="user.isAdmin">
                <a class="nav-link" :class="[this.currentNav == 'manageBillingDetails' ? 'active' : '']"
                    aria-current="page" @click="manageBillingDetails()">Manage Billing details</a>
            </li>
        </ul>

        <br />
        <div class="container" v-if="this.currentNav == 'changePassword'">
            <form>
                <div class="row">
                    <label class="col-sm-3 col-form-label left">Name</label>
                    <div class="col-sm-6">
                        <input type="text" readonly class="form-control-plaintext" v-model="user.name">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Email</label>
                    <div class="col-sm-6">
                        <input type="text" readonly class="form-control-plaintext" v-model="user.email">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Password</label>
                    <div class="col-sm-6">
                        <input type="password" class="form-control" v-model="user.password">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Re-enter Password</label>
                    <div class="col-sm-6">
                        <input type="password" class="form-control" v-model="user.repassword">
                    </div>
                </div>

                <div class="row">
                    <small class="form-text text-muted col-sm-9">
                        Your password must be 8-20 characters long, contain letters and numbers, and must not contain
                        spaces, special characters, or emoji.
                    </small>
                </div>

                <button type="submit" class="btn btn-primary" @click.prevent="changePassword()">Submit</button>
            </form>
        </div>

        <div class="container" v-if="this.currentNav == 'manageUsers' && user.isAdmin">
            <div class="" style="overflow-x: auto">
                <table class="table table-striped table-hover text-nowrap pointer">
                    <thead class="table-dark">
                        <tr>
                            <th class="left">Name</th>
                            <th class="left">Email</th>
                            <th class="fitContent">Admin</th>
                            <th class="fitContent">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="users.length == 0">
                            <td colspan="4">No data found.</td>
                        </tr>
                        <tr v-for="user in users" v-bind:key="user.id">
                            <td class="left">{{ user.name }}</td>
                            <td class="left">{{ user.email }}</td>
                            <td>{{ user.isAdmin ? "Yes" : "No" }}</td>
                            <td @click.stop>
                                <button type="button" title="Change password" class="btn btn-light"
                                    @click="changePasswordForUser(user)" style="margin-right: 0.2em;"><i
                                        class="bi bi-key link-primary"></i></button>
                                <button type="button" title="Update user" class="btn btn-light" @click="editObj(user)"
                                    style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>
                                <button type="button" title="Remove user" class="btn btn-light"
                                    @click="deleteObj(user.id)"><i class="bi bi-trash link-danger"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button type="button" ref="openModal" class="btn btn-link" data-bs-toggle="modal"
                data-bs-target="#userModal" @click="addNewUser()">
                Add new user</button>
        </div>

        <div class="container" v-if="this.currentNav == 'manageBillingDetails' && user.isAdmin">
            <form>
                <div class="row">
                    <label class="col-sm-3 col-form-label left">Business name</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.name">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Flat/Building</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.building">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Street</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.street">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Area</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.area">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">City</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.city">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">State</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.state">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Pin</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.pin">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">Phone</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.phone">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">FSSAI no</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.fssai">
                    </div>
                </div>

                <div class="row">
                    <label class="col-sm-3 col-form-label left">GSTIN</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="billing.gstin">
                    </div>
                </div>

                <button type="submit" class="btn btn-primary" @click.prevent="updateBilling()">Submit</button>
            </form>
        </div>



        <div class="modal fade" id="userModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header bg-dark">
                        <h5 class="modal-title" style="color: white" id="staticBackdropLabel">{{ modalHeading }}
                        </h5>
                        <button ref="closeModal" type="button" data-bs-dismiss="modal">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3" v-if="this.newUser.id == null || !this.showPasswordChange">
                            <label class="col-sm-3 col-form-label left">Name</label>
                            <div class="col-sm-6">
                                <input type="text" class="form-control" v-model="newUser.name">
                            </div>
                        </div>

                        <div class="row mb-3" v-if="this.newUser.id == null || !this.showPasswordChange">
                            <label class="col-sm-3 col-form-label left">Email</label>
                            <div class="col-sm-6" v-if="showPasswordChange">
                                <input type="text" class="form-control" v-model="newUser.email">
                            </div>
                            <div class="col-sm-6" v-if="!showPasswordChange">
                                <input type="text" readonly class="form-control-plaintext" v-model="newUser.email">
                            </div>
                        </div>

                        <div class="row" v-if="this.newUser.id == null || !this.showPasswordChange">
                            <div class="col-sm-3 left"></div>
                            <div class="col-sm-6 left">
                                <input class="form-check-input" id="inlineCheckbox1" v-model="newUser.isAdmin"
                                    type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox1" style="margin-left: 0.5em;">
                                    Admin</label>
                            </div>
                        </div>

                        <div v-if="showPasswordChange">
                            <div class="row">
                                <label class="col-sm-3 col-form-label left">Password</label>
                                <div class="col-sm-6">
                                    <input type="password" class="form-control" v-model="newUser.password">
                                </div>
                            </div>

                            <div class="row">
                                <label class="col-sm-3 col-form-label left">Re-enter Password</label>
                                <div class="col-sm-6">
                                    <input type="password" class="form-control" v-model="newUser.repassword">
                                </div>
                            </div>

                            <div class="row">
                                <small class="form-text text-muted col-sm-9">
                                    Your password must be 8-20 characters long, contain letters and numbers, and must
                                    not
                                    contain
                                    spaces, special characters, or emoji.
                                </small>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="saveUser()">Submit</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
    
<script>

import { commonMixin } from '@/js/common';

export default {
    name: 'app-user',
    data() {
        return {
            user: {},
            users: [],
            billing: {},
            currentNav: "changePassword",
            showInfo: false,
            modalHeading: "",
            newUser: {},
            showPasswordChange: false,
        }
    },
    methods: {
        async addNewUser() {
            this.newUser = {};
            this.newUser.name = "";
            this.newUser.email = "";
            this.newUser.isAdmin = false;
            this.newUser.password = "";
            this.newUser.repassword = "";
            this.modalHeading = "Add new user";
            this.showPasswordChange = true;
        },
        async changePasswordForUser(user) {
            this.newUser = {},
                this.$refs.openModal.click();
            this.showPasswordChange = true;
            this.newUser.name = user.name;
            this.newUser.email = user.email;
            this.newUser.id = user.id;
            this.modalHeading = "Change password for " + this.newUser.name;
        },
        async editObj(user) {
            this.newUser = {}
            this.$refs.openModal.click();
            this.showPasswordChange = false;
            this.newUser.name = user.name;
            this.newUser.email = user.email;
            this.newUser.id = user.id;
            this.newUser.isAdmin = user.isAdmin == "1";
            this.modalHeading = "Update " + this.newUser.name;
        },
        async saveUser() {
            if (this.newUser.name == null || this.newUser.name.length < 3) {
                alert("Enter valid username");
                return false;
            }

            if (this.newUser.email == null || this.newUser.name.email < 3) {
                alert("Enter valid email");
                return false;
            }
            this.$refs.closeModal.click();

            var data = {};
            if (this.newUser.id) {
                if (this.showPasswordChange) {
                    if (!this.validatePassword(this.newUser.password, this.newUser.repassword)) {
                        return;
                    }
                    data = await commonMixin.methods.callPost("user/changePasswordForUser", this.newUser);
                } else {
                    data = await commonMixin.methods.callPost("user/update", this.newUser);
                }
            } else {
                if (!this.validatePassword(this.newUser.password, this.newUser.repassword)) {
                    return;
                }
                data = await commonMixin.methods.callPost("user/register", this.newUser);
            }
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                return;
            }
            commonMixin.methods.showToast(data.message);
            this.manageUsers();
        },

        validatePassword(pass1, pass2) {
            if (pass1 == null || pass1.length < 8 || pass1.length > 20) {
                alert("Password must be 8-20 characters long.");
                return false;
            }
            var regularExpression = /^[a-zA-Z0-9]+$/;
            var letter = /[a-zA-Z]/;
            var number = /[0-9]/;
            if (!letter.test(pass1) || !number.test(pass1) || !regularExpression.test(pass1)) {
                alert("Password must be Alphanumeric. It must contain at least 1 Alphabet and 1 number.\nSymbols are not allowed");
                return false;
            }
            if (pass1 != pass2) {
                alert("Both password should match.");
                return false;
            }
            return true;
        },

        async changePassword() {
            if (!this.validatePassword(this.user.password, this.user.repassword)) {
                return;
            }
            var data = await commonMixin.methods.callPost('user/changePassword', this.user);
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                return;
            }
            commonMixin.methods.showToast(data.message);
            this.user.password = "";
            this.user.repassword = "";
        },
        async manageUsers() {
            this.currentNav = 'manageUsers';
            var data = await commonMixin.methods.callPost("user/getAll");

            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.user.isAdmin = false;
                this.currentNav = 'changePassword';
                return;
            }
            this.users = data.obj;
        },

        async manageBillingDetails() {
            this.currentNav = 'manageBillingDetails';
            var data = await commonMixin.methods.callGet("util/billing_details_");

            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                return;
            }
            var objs = await data.obj;
            for (var i = 0; i < objs.length; i++) {
                objs[i].name = objs[i].param.replace("billing_details_", "");
                this.billing[objs[i].name] = objs[i].value;
            }
        },

        async updateBilling() {
            var data = {};
            data.params = this.billing;
            data = await commonMixin.methods.callPost("util/billing_details_", data);
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                return;
            }
            commonMixin.methods.showToast(data.message);
        },

        async save() {
            if (this.item.name == "" || this.item.name == null) {
                alert("Item name can't be empty.");
                return;
            }
            if (isNaN(this.item.weight_in_grms)) {
                alert("Enter valid weight");
                return;
            }
            if (isNaN(this.item.rate)) {
                alert("Enter valid rate");
                return;
            }
            if (isNaN(this.item.mrp)) {
                alert("Enter valid MRP");
                return;
            }
            var data;
            if (this.$route.params.id) {
                data = await commonMixin.methods.callPost('products/' + this.$route.params.id, this.item);
                commonMixin.methods.showToast(data.message);
            } else {
                data = await commonMixin.methods.callPost('products', this.item);
                commonMixin.methods.showToast(data.message);
            }
            if (data.status != "0") {
                return;
            }
            this.$router.push({ name: 'ItemMain' });
        }
    },
    async mounted() {
        this.currentNav = "changePassword";
        var data = await commonMixin.methods.callPost("user/get");

        if (data.status == "-1") {
            commonMixin.methods.showError(data.message);
            this.$router.push({ name: 'ItemMain' });
            return;
        }
        this.user = await data;
        this.showInfo = true;
    }
}
</script>

<style>
.nav-tabs li {
    cursor: pointer;
}

.fitContent {
    white-space: nowrap;
    width: 1%;
}
</style>