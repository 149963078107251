<template>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
      <router-link to="/" class="navbar-brand" >{{$parent.appName}}</router-link>
    <button ref="navBotton" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" @click="clickNavButton()">
        <!-- <li class="nav-item">
          <router-link to="/" class="nav-link" :class="[this.$route.name == 'Dashboard' ? 'active' : '']" aria-current="page">Dashboard</router-link>
        </li> -->
        <li class="nav-item" >
          <router-link to="/item" class="nav-link" :class="[$route.path.startsWith('/item') ? 'active' : '']" >Item</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/customer" class="nav-link" :class="[$route.path.startsWith('/Customer') ? 'active' : '']" >Customer</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/sale" class="nav-link" :class="[$route.path.startsWith('/Sale') ? 'active' : '']" >Sale</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/transaction" class="nav-link" :class="[$route.path.startsWith('/Transaction') ? 'active' : '']" >Transaction</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/user" class="nav-link" :class="[$route.path.startsWith('/User') ? 'active' : '']" >User</router-link>
        </li>
    </ul>
    <ul class="navbar-nav">
        <button class="btn btn-danger navbar-expand" v-on:click.prevent="$parent.logout()">Logout</button>
    </ul>
    </div>
  </div>
</nav>

</template>


<script>

export default {
    name: 'app-navbar',
    data() {
        return {

        }
    },
    methods: {
      clickNavButton() {
        if(this.$refs.navBotton.offsetParent) {
          this.$refs.navBotton.click();
        }
      }
    },
    computed: {
      
    }
};

</script>

<style>

</style>