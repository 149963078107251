<template>
    <div>
        <br>
    </div>
    <div class="container" v-if="showInfo">
        <div class="row">
            <div class="col-12 right">
                <button type="button" class="btn btn-link" @click="goBack()" style="margin-right: 1rem;"><span
                        class="bi bi-arrow-left-circle" /> Go
                    back</button>
                <!-- <button type="button" title="Print" class="btn btn-primary" @click="saveAsPDF()"
                    style="margin-right: 1rem;"><i class="bi bi-files link"></i> Save As PDF</button> -->

                <button type="button" title="Print" class="btn btn-primary" @click="printSale()"><i
                        class="bi bi-printer link"></i> Print</button>
            </div>
        </div>
    </div>
    <div class="page" v-if="showInfo">
        <page id="page">
            <div style="width: 90%; margin-left: auto; margin-right: 1.5%;">

                <br><br>
                <table style="width: 100%; table-layout: fixed;">
                    <tr>
                        <td></td>
                        <td><b>TAX INVOICE</b></td>
                        <td class="right">(ORIGINAL FOR RECIPIENT)</td>
                    </tr>
                </table>

                <div class="row" style="margin: 1px 1px 0px">
                    <div class="col-sm-6 left" style="border: 1px solid black !important;">
                        <b>{{billing.name}}</b>
                        <br>{{billing.building}}, {{billing.street}},
                        <br>{{billing.area}},
                        <br>{{billing.city}}-{{billing.pin}}, {{billing.state}}.
                        <br>Phone: {{billing.phone}}
                        <br>FSSAI No.: {{billing.fssai}}
                        <br>GSTIN/UIN: {{billing.gstin}}
                    </div>

                    <div class="col-sm-6 left" style="border: 1px solid black !important;">
                        <div class="row" style="margin-bottom: 0">
                            <div class="col-sm-6 left" style="border-bottom: 1px solid black !important;">
                                Invoice no.:<br>
                                <b>{{ sale.id }}</b>
                            </div>
                            <div class="col-sm-6 left" style="border-bottom: 1px solid black !important;
                            border-left: 1px solid black !important;">
                                Dated:<br>
                                <b>{{ sale.bill_date }}</b>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 0">
                            <div class="col-sm-12 left" style="white-space: pre-wrap;">
                                <span><small class="fst-italict">Buyer (Bill to)</small></span>
                                <br><b>{{ sale.entity_name }}</b>
                                <br>{{ sale.address }}
                                <br><span v-if="sale.gstin != null">GSTIN/UIN: {{ sale.gstin }}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div style="min-height: 18.5cm">
                    <table style="width: 100%; margin-top: 0.35cm; border: 1px solid black">
                        <thead class="saleTable">
                            <tr>
                                <th class="fitContent">Sr.<br>No.</th>
                                <th class="left">Description</th>
                                <th class="fitContent">HSN/SAC</th>
                                <th class="fitContent">Qty</th>
                                <th class="fitContent">Rate</th>
                                <th class="fitContent">GST<br>Rate</th>
                                <th class="fitContent">Amount</th>
                            </tr>
                        </thead>
                        <tbody class="saleTable">
                            <tr v-for="(item, index) in sale.items" v-bind:key="item.id">
                                <td>{{ index + 1 }}</td>
                                <td class="left"><span>{{ item.detail.name }}</span>
                                    <p class="text-muted" style="font-size: smaller; margin-bottom: 0;">{{ item.comment
                                    }}
                                    </p>
                                </td>
                                <td class="left">{{ item.item.barcode }}</td>
                                <td>{{ item.qty }}</td>
                                <td class="right">{{ item.detail.rate }}</td>
                                <td>{{ item.tax_percentage }}%</td>
                                <td class="right">{{ item.detail.taxableAmount }}</td>
                            </tr>
                            <!-- End of items -->

                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="right" style="border-top: 1px solid black;"><b>{{
                                        parseFloat(sale.totalTaxable).toFixed(2)
                                }}</b></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="right">CGST</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="right">{{ parseFloat(sale.totalTax / 2).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td class="right">SGST</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="right">{{ parseFloat(sale.totalTax / 2).toFixed(2) }}</td>
                            </tr>
                            <tr v-if="sale.adjustment != 0">
                                <td></td>
                                <td class="right">Round off</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="right">{{ parseFloat(sale.adjustment).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <th colspan="2" class="right">Total</th>
                                <th></th>
                                <th>{{ sale.totalQty }}</th>
                                <th></th>
                                <th></th>
                                <th class="right">{{ parseFloat(Math.round(sale.total)).toFixed(2) }}</th>
                            </tr>
                            <tr>
                                <td colspan="7" class="left">
                                    Amount charageable (in words)<br>
                                    <b>{{ price_in_words(parseFloat(sale.total).toFixed(0)) }} only</b>
                                </td>
                            </tr>
                        </tbody>

                    </table>

                    <table style="width: 100%; margin-top: 0.35cm; border: 1px solid black">
                        <thead class="saleTable">
                            <tr>
                                <th rowspan="2">HSN/SAC</th>
                                <th rowspan="2" class="fitContent">Taxable<br>Value</th>
                                <th class="fitContent" colspan="2">Central Tax</th>
                                <th class="fitContent" colspan="2">State Tax</th>
                                <th rowspan="2" class="fitContent">Total<br>Tax Amount</th>
                            </tr>
                            <tr>
                                <th class="fitContent">Rate</th>
                                <th class="fitContent">Amount</th>
                                <th class="fitContent">Rate</th>
                                <th class="fitContent">Amount</th>
                            </tr>
                        </thead>
                        <tbody class="saleTable">
                            <tr v-for="(item, index) in itemMap" :key="index">
                                <td class="left">{{ item[0] }}</td>
                                <td class="right">{{ itemMap.get(item[0]).totalTaxableAmount }}</td>
                                <td class="right">{{ itemMap.get(item[0]).taxPercentage / 2 }}%</td>
                                <td class="right">{{ parseFloat(itemMap.get(item[0]).totalTaxAmount / 2).toFixed(2) }}
                                </td>
                                <td class="right">{{ itemMap.get(item[0]).taxPercentage / 2 }}%</td>
                                <td class="right">{{ parseFloat(itemMap.get(item[0]).totalTaxAmount / 2).toFixed(2) }}
                                </td>
                                <td class="right">{{ parseFloat(itemMap.get(item[0]).totalTaxAmount).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <th class="right">Total</th>
                                <th class="right">{{ parseFloat(sale.totalTaxable).toFixed(2) }}</th>
                                <th></th>
                                <th class="right">{{ parseFloat(sale.totalTax / 2).toFixed(2) }}</th>
                                <th></th>
                                <th class="right">{{ parseFloat(sale.totalTax / 2).toFixed(2) }}</th>
                                <th class="right">{{ parseFloat(sale.totalTax).toFixed(2) }}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <table style="width: 100%; margin-top: 0.35cm; border: 1px solid black !important; table-layout: fixed;">
                    <tr>
                        <td class="left" style="vertical-align: top;">
                            Remarks:
                        </td>
                        <td class="right" style="border: 1px solid black !important;">
                            <p style="margin-bottom: 2cm;"><b>for Vighnaharta Industries</b></p>
                            Authorised signature.
                        </td>
                    </tr>
                </table>
                <br>
            </div>



        </page>
    </div>
</template>


<script>

import { commonMixin } from '@/js/common';

export default {
    name: 'app-item-info',
    data() {
        return {
            sale: {},
            itemMap: new Map(),
            billing: {},
            showInfo: false,
        }
    },
    methods: {
        price_in_words(price) {
            var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
                dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
                tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
                handle_tens = function (dgt, prevDgt) {
                    return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
                },
                handle_utlc = function (dgt, nxtDgt, denom) {
                    return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
                };

            var str = "",
                digitIdx = 0,
                digit = 0,
                nxtDigit = 0,
                words = [];
            if (price += "", isNaN(parseInt(price))) str = "";
            else if (parseInt(price) > 0 && price.length <= 10) {
                for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
                    case 0:
                        words.push(handle_utlc(digit, nxtDigit, ""));
                        break;
                    case 1:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 2:
                        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
                        break;
                    case 3:
                        words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                        break;
                    case 4:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 5:
                        words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                        break;
                    case 6:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 7:
                        words.push(handle_utlc(digit, nxtDigit, "Crore"));
                        break;
                    case 8:
                        words.push(handle_tens(digit, price[digitIdx + 1]));
                        break;
                    case 9:
                        words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
                }
                str = words.reverse().join("")
            } else str = "";
            return str

        },
        calculateTax() {
            this.itemMap = new Map();
            for (var i = 0; i < this.sale.items.length; i++) {
                var item = this.sale.items[i];
                var barcode = item.item.barcode + " ";
                if (item.item.barcode == null) {
                    barcode = "Tax slab - " + item.tax_percentage;
                }
                if (this.itemMap.has(barcode)) {
                    this.itemMap.get(barcode).arr.push(item);
                } else {
                    var obj = {};
                    obj.arr = [];
                    obj.arr.push(item);
                    this.itemMap.set(barcode, obj);
                }

            }

            this.itemMap.forEach(function (value, key) {
                value.totalTaxableAmount = 0;
                value.totalTaxAmount = 0;
                value.taxPercentage = 0;
                value.barcode = key;
                for (var i = 0; i < value.arr.length; i++) {
                    value.totalTaxableAmount = parseFloat(parseFloat(value.totalTaxableAmount) + parseFloat(value.arr[i].detail.taxableAmount)).toFixed(2);
                    value.totalTaxAmount = parseFloat(parseFloat(value.totalTaxAmount) + parseFloat(value.arr[i].detail.gst)).toFixed(2);
                    value.taxPercentage = value.arr[i].tax_percentage;
                }
            });
        },
        printSale() {
            window.print();
        },
        saveAsPDF() {

        },
        goBack() {
            this.$router.back();
        }
    },
    async mounted() {
        if (this.$route.params.sale) {
            this.sale = JSON.parse(this.$route.params.sale);
            console.log(this.sale);
        } else {
            this.$router.push({ name: 'SaleMain' });
        }

        var data = await commonMixin.methods.callGet("util/billing_details_");

        if (data.status == "-1") {
            commonMixin.methods.showError(data.message);
            return;
        }
        var objs = await data.obj;
        for (var i = 0; i < objs.length; i++) {
            objs[i].name = objs[i].param.replace("billing_details_", "");
            this.billing[objs[i].name] = objs[i].value;
        }

        this.calculateTax();
        this.showInfo = true;
    }
}
</script>

<style scoped>
page {
    background: white;
    display: block;
    width: 21cm;
    /* font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
    font-family: "Times New Roman", Times, serif;
}

.page {
    background: gray;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    width: 21cm;
    /* height: 29.7cm; */
    line-height: normal;
}

.saleTable tr th,
.saleTable tr td {
    border: 1px solid;
    padding-right: calc(var(--bs-gutter-x) * .1);
    padding-left: calc(var(--bs-gutter-x) * .1);
}

.saleTable tr td {
    border-bottom: 0;
    border-top: 0;
    margin-bottom: 0.4cm;
    padding-bottom: 0.25cm;
}

/* @page {
    margin: none;
    size: a4;
} */

@media print {

    body,
    page {
        margin: 0;
    }

    body,
    body * {
        visibility: hidden;
    }

    page,
    page * {
        visibility: visible;
    }

    page {
        position: absolute;
        left: 0;
        top: 0;
    }
}
</style>
