<template>
    <CommonInfo :objId="cust.id" :heading="cust.name" :obj="obj" :addNew="addNew" :editObj="editObj"
        :deleteObj="deleteObj"></CommonInfo>


    <div v-if="this.showInfo">
        <h5 class="right" 
        v-if="cust.balance !=null" 
        :class="cust.balance.balance < 0 ? 'red' : 'green'">
        <b>Balance: ₹ {{parseFloat(cust.balance.balance).toFixed(2)}}</b></h5>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" :class="[this.currentNav == 'basic' ? 'active' : '']" aria-current="page"
                    @click="this.currentNav='basic'">Basic Details</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="[this.currentNav == 'address' ? 'active' : '']" aria-current="page"
                    @click="this.currentNav='address'">Address</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="[this.currentNav == 'bank' ? 'active' : '']" aria-current="page"
                    @click="this.currentNav='bank'">Bank Details</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="[this.currentNav == 'openingBal' ? 'active' : '']" aria-current="page"
                    @click="this.currentNav='openingBal'">Opening Balance</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="[this.currentNav == 'transactions' ? 'active' : '']" aria-current="page"
                    @click="getTransactions()">Transactions</a>
            </li>
        </ul>

        <div class="left info">
            <br>
            <table class="table table-striped">
                <tbody v-if="this.currentNav == 'basic'">
                    <tr>
                        <th>Name:</th>
                        <td>{{cust.name}}</td>
                    </tr>
                    <tr>
                        <th>Contact person:</th>
                        <td>{{cust.contact_person}}</td>
                    </tr>
                    <tr>
                        <th>Mobile:</th>
                        <td>{{cust.mobile}}</td>
                    </tr>
                    <tr>
                        <th>Phone:</th>
                        <td>{{cust.phone}}</td>
                    </tr>
                    <tr>
                        <th>Email:</th>
                        <td>{{cust.email}}</td>
                    </tr>
                    <tr>
                        <th>Website:</th>
                        <td>{{cust.website}}</td>
                    </tr>
                    <tr>
                        <th>Remark:</th>
                        <td>{{cust.remark}}</td>
                    </tr>
                    <tr>
                        <th>Created:</th>
                        <td>{{getConvertedDate(cust.created_at)}}</td>
                    </tr>
                    <tr>
                        <th>Last updated:</th>
                        <td>{{getConvertedDate(cust.updated_at)}}</td>
                    </tr>
                </tbody>

                <tbody v-if="this.currentNav == 'address'">
                    <tr>
                        <th>Address:</th>
                        <td>{{cust.address}}</td>
                    </tr>
                    <tr>
                        <th>City:</th>
                        <td>{{cust.city}}</td>
                    </tr>
                    <tr>
                        <th>Pin:</th>
                        <td>{{cust.pin}}</td>
                    </tr>
                    <tr>
                        <th>State:</th>
                        <td>{{cust.state}}</td>
                    </tr>
                </tbody>

                <tbody v-if="this.currentNav == 'bank'">
                    <tr>
                        <th>GSTIN:</th>
                        <td>{{cust.GSTIN}}</td>
                    </tr>
                    <tr>
                        <th>PAN:</th>
                        <td>{{cust.PAN}}</td>
                    </tr>
                    <tr>
                        <th>Bank A/c no.:</th>
                        <td>{{cust.bank_account_no}}</td>
                    </tr>
                    <tr>
                        <th>Bank IFSC:</th>
                        <td>{{cust.bank_ifsc}}</td>
                    </tr>
                    <tr>
                        <th>Branch:</th>
                        <td>{{cust.branch}}</td>
                    </tr>
                </tbody>

                <tbody v-if="this.currentNav == 'openingBal'">
                    <tr>
                        <th>Amount:</th>
                        <td>{{cust.transaction.amount}}</td>
                    </tr>
                    <tr>
                        <th>Type:</th>
                        <td>{{cust.transaction.type == 0 ? "Receivable" : "Payable"}}</td>
                    </tr>
                    <tr>
                        <th>Payment details:</th>
                        <td>{{cust.transaction.payment_details}}</td>
                    </tr>
                    <tr>
                        <th>Note:</th>
                        <td>{{cust.transaction.note}}</td>
                    </tr>
                </tbody>

                <tbody v-if="this.currentNav == 'transactions'">
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Payment Type</th>
                        <th>Type</th>
                    </tr>
                    <tr v-for="trans in transactions" :key="trans.id">
                        <th>{{trans.id}}</th>
                        <td>{{trans.date}}</td>
                        <td>{{trans.amount}}</td>

                        <td v-if="trans.payment_type == 0">Opening Balance</td>
                        <td v-if="trans.payment_type == 1">Cash</td>
                        <td v-if="trans.payment_type == 2">Cheque</td>
                        <td v-if="trans.payment_type == 3">UPI / Online</td>
                        <td v-if="trans.payment_type == 4">Receivable / Payable</td>

                        <td v-if="trans.type == 0 && trans.payment_type == 0">Receivable</td>
                        <td v-if="trans.type == 1 && trans.payment_type == 0">Payable</td>
                        <td v-if="trans.type == 0 && trans.payment_type != 0">Paid</td>
                        <td v-if="trans.type == 1 && trans.payment_type != 0">Received</td>

                    </tr>
                </tbody>
            </table>
            <h1 style="text-align: center" v-if="this.currentNav == 'transactions' && 
            (transactions == null || transactions.length == 0)"><br>No Records founds!</h1>
        </div>




    </div>
</template>

<script>
import { commonMixin } from '@/js/common';
import CommonInfo from '../Common/CommonInfo.vue';

export default {
    name: 'app-customer-info',
    data() {
        return {
            cust: {},
            obj: {},
            currentNav: 'basic',
            transactions: null,
        }
    },
    components: {
        CommonInfo
    },
    methods: {

        addNew() {
            this.$router.push({ name: 'CustomerAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'CustomerUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('entity/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.$router.push({ name: 'CustomerMain' });
            }
        },
        getConvertedDate(date) {
            return commonMixin.methods.getConvertedDate(date);
        },
        async getTransactions() {
            this.currentNav='transactions';
            if(this.transactions != null) {
                return;
            }
            var data = await commonMixin.methods.callGet("entity/transactions/" + this.$route.params.id, "");
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.showInfo = false;
                return;
            }
            this.transactions = data.obj;
        }
    },
    async mounted() {
        if (this.$route.params.obj) {
            this.cust = JSON.parse(this.$route.params.obj);
        } else {
            var data = await commonMixin.methods.callGet("entity/" + this.$route.params.id, "");
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.showInfo = false;
                return;
            }
            this.cust = data.obj;
        }
        this.showInfo = true;
    }
}
</script>
<style>
.red {
    color: red;
}
.green {
    color: green;
}
.right {
    text-align: right;
}
</style>
