<template>
    <br>
    <br>
    <h1>Sales</h1>

    <div style="text-align: right; margin-bottom: 0.2em;">
        <button type="button" title="Add new" class="btn btn-light" @click="exportCSV()">
            <span class="bi bi-arrow-bar-up link-success"> Export</span></button>
       
        <button type="button" title="Add new" class="btn btn-light" @click="addNew()">
            <span class="bi bi-plus-square link-success"> Add New</span></button>
    </div>
    <div class="" style="overflow-x: auto">
        <table class="table table-striped table-hover text-nowrap pointer">
            <thead class="table-dark">
                <tr>
                    <th>ID</th>
                    <th class="left">Customer Name</th>
                    <th>Bill date</th>
                    <th>Total Amount</th>
                    <th>Received Amount</th>
                    <th>Pending Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="objs.length == 0">
                    <td colspan="8">No data found.</td>
                </tr>
                <tr v-for="obj in objs" v-bind:key="obj.id" @click="getInfo(obj.id)">
                    <th scope="row">{{ obj.id }}</th>
                    <td class="left">{{ obj.entityName }}</td>
                    <td>{{ obj.bill_date }}</td>
                    <td>₹ {{ parseFloat(obj.total).toFixed(2) }}</td>
                    <td>₹ {{ parseFloat(obj.received).toFixed(2) }}</td>
                    <td><span v-if="obj.total != obj.received">₹ {{ parseFloat(obj.total).toFixed(2) -
                            parseFloat(obj.received).toFixed(2)
                    }}</span></td>
                    <td>
                        <span style="border-radius: 10px" class="badge bg-success text-success" title="Received"
                            v-if="obj.received >= obj.total">.</span>
                        <span style="border-radius: 10px" class="badge bg-danger text-danger" title="Pending"
                            v-else>.</span>

                    </td>
                    <td @click.stop>
                        <button type="button" title="Print" class="btn btn-light" @click="printObj(obj.id)"
                            style="margin-right: 0.2em;"><i class="bi bi-printer link-primary"></i></button>
                        <button type="button" title="Update" class="btn btn-light" @click="editObj(obj.id)"
                            style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>
                        <button type="button" title="Remove" class="btn btn-light" @click="deleteObj(obj.id)"><i
                                class="bi bi-trash link-danger"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-sale-main',
    data() {
        return {
            objs: {},
        }
    },
    methods: {
        getInfo(id) {
            for (var i = 0; i < this.objs.length; i++) {
                if (this.objs[i].id == id) {
                    this.$router.push({ name: 'SaleInfo', params: { "id": id } })
                    break;
                }
            }
        },
        printObj(id) {
            for (var i = 0; i < this.objs.length; i++) {
                if (this.objs[i].id == id) {
                    this.$router.push({ name: 'SaleInfo', params: { "id": id, "isPrint": true } })
                    break;
                }
            }
        },
        addNew() {
            this.$router.push({ name: 'SaleAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'SaleUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('sale/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.items = await (await commonMixin.methods.callGet("sale", "")).obj;
            }
        },
        exportCSV() {
            var rows = [
                ["id", "Customer name", "Bill date", "Total amount", "Received amount", "Pending Amount", 
                "created_at","updated_at"]
            ];
            for(var i=0; i<this.objs.length; i++) {
                var obj = this.objs[i];
                var temp = [];
                temp.push(obj.id);
                temp.push(obj.entityName);
                temp.push(obj.bill_date);
                temp.push(parseFloat(obj.total).toFixed(2));
                temp.push(parseFloat(obj.received).toFixed(2));
                temp.push(parseFloat(obj.total - obj.received).toFixed(2));
                temp.push(obj.created_at);
                temp.push(obj.updated_at);
                rows.push(temp);
            }
            console.log(rows);
            commonMixin.methods.createCSV(rows, "Sales.csv");
        },
    },
    async mounted() {
        this.objs = await (await commonMixin.methods.callGet("sale", "")).obj;
    }
}
</script>
    
<style>
.left {
    text-align: left;
}

.pointer {
    cursor: pointer;
}

tr a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    color: inherit;
    text-decoration: inherit;
}

.row {
    margin-bottom: 2em;
}
</style>