<template>
    <router-view></router-view>
</template>
    
<script>
export default {
    name: 'app-Sale',
    data() {
        return {

        }
    }
}
</script>