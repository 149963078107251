<template>
<section class="vh-100 gradient-custom">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-dark text-white" style="border-radius: 1rem;">
          <div class="card-body p-5 text-center">

            <div class="mb-md-5 mt-md-4 pb-5">

              <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
              <p class="text-white-50 mb-5"></p>

              <div class="form-outline form-white mb-4">
                <input v-model="cred.email" placeholder="Email" type="email" class="form-control form-control-lg" />
              </div>

              <div class="form-outline form-white mb-4">
                <input v-model="cred.password" placeholder="Password" type="password" class="form-control form-control-lg"  @keyup.enter="login()" />
              </div>

              <!-- <p class="small mb-5 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p> -->

              <button class="btn btn-outline-light btn-lg px-5" type="submit" @click="login()">Login</button>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import {commonMixin} from '../js/common.js'

    export default{
        name:"app-login",

        data() {
            return {
                cred: {
                  email: null,
                  password: null,
                }
            }
            
        },
        mounted() {
          if(localStorage.getItem("user-access-token") != null) {
            this.$router.push({ name: 'Dashboard' })
          }
        },
        methods: {
          async login() {
            localStorage.clear();
            var data = await commonMixin.methods.callPost('user/login', this.cred);
            if(!data.access_token) {
              commonMixin.methods.showError(data.message);
              return;
            }
              commonMixin.methods.showToast("Logged in successfully.");
              localStorage.setItem("user-access-token", data.access_token);
              this.$router.push({ name: 'Dashboard' })
          }
        }
    }
</script>


<style scoped>
.gradient-custom  {
/* fallback for old browsers */
background: #6a11cb;

/* Chrome 10-25, Safari 5.1-6 */
background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
}
</style>