<template>
    <router-view></router-view>
    </template>
    
    <script>
    export default {
        name: 'app-customer',
        data() {
            return {
            }
        },
    }
    </script>
    