<template>
    <br />
    <h1 class="display-6 left">{{ heading }}</h1>
    <br />
    <div class="row">

        <div class="col-6">

            <form>
                <div class="row mb-4">
                    <label class="col-sm-4 col-form-label left">Name</label>
                    <div class="col-sm-8">

                        <div class="dropdown left">
                            <button class="btn left" required type="button" data-bs-toggle="dropdown"
                                style="width: 100%; border: 1px solid gray;">
                                <span v-if="transaction.cust == null">
                                    Select customer
                                </span>

                                <span v-if="transaction.cust != null">
                                    {{ transaction.cust.name }}
                                </span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" style="width: 100%">
                                <li v-for="cust in custs" v-bind:key="cust.id" @click="custChanged(cust)">
                                    <div class="dropdown-item">
                                        {{ cust.name }}
                                        <span style="float: right" :class="cust.balance.balance < 0 ? 'red' : 'green'">
                                            ₹ {{ cust.balance.balance }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div class="row mb-4">
                    <label class="col-sm-4 col-form-label left">Date</label>
                    <div class="col-sm-8">
                        <input required type="date" class="form-control" v-model="transaction.date" />
                    </div>
                </div>

                <div class="row mb-4">
                    <label class="col-sm-4 col-form-label left">Amount</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="transaction.amount">
                    </div>
                </div>

                <div class="row mb-4" v-if="transaction.payment_type != 0">
                    <label class="col-sm-4 col-form-label left">Payment Type</label>
                    <div class="col-sm-8">
                        <select class="form-select" v-model="transaction.payment_type">
                            <option value="1">Cash</option>
                            <option value="2">Cheque</option>
                            <option value="3">UPI / Online</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-4">
                    <label class="col-sm-4 col-form-label left">Payment details</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="transaction.payment_details">
                    </div>
                </div>

                <div class="row mb-4">
                    <label class="col-sm-4 col-form-label left">Note</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="transaction.note">
                    </div>
                </div>

                <div class="row mb-4"
                    v-if="transaction.cust != null && !isNaN(transaction.amount) && transaction.amount != ''">
                    <label class="col-sm-4 col-form-label left">Anticipated balance</label>
                    <div class="col-sm-8" v-if="heading == 'Update'">
                        <span class="form-control left">₹ {{ parseFloat(parseFloat(transaction.cust.balance.balance) -
                                parseFloat(transaction.amount) + parseFloat(original.amount)).toFixed(2)
                        }}</span>
                    </div>
                    <div class="col-sm-8" v-else>
                        <span class="form-control left">₹ {{ parseFloat(parseFloat(transaction.cust.balance.balance) -
                                parseFloat(transaction.amount)).toFixed(2)
                        }}</span>
                    </div>
                </div>

                <button type="button" class="btn btn-link text-danger" @click.prevent="cancel()">Cancel</button>
                <button type="submit" class="btn btn-primary" @click.prevent="addObj()">Submit</button>
                <br><br>
            </form>
        </div>

        <div v-if="heading == 'Update'" class="col-md-6">
            <h1 style="font-size: calc(1.375rem + 0.5vw) !important" class="display-6 left">Linked bills</h1>
            <table class="table table-striped table-hover text-nowrap pointer">
                <thead class="table-dark">
                    <tr>
                        <th>Bill ID</th>
                        <th>Bill Date</th>
                        <th>Bill Amount</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="link in transaction.links" v-bind:key="link.id">
                        <td @click="viewBill(link.bill_id)">{{ link.bill_id }}</td>
                        <td>{{ link.bill_date }}</td>
                        <td class="right">₹ {{ parseFloat(link.amount).toFixed(2) }}</td>

                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { commonMixin } from '@/js/common';

export default {
    name: 'app-transaction-add-update',
    data() {
        return {
            transaction: {
                date: commonMixin.methods.getJustDate(new Date()), type: 1, amount: 0.00,
                payment_type: 1,
                payment_details: "", note: ""
            },
            heading: "",
            custs: [],
            original: { amount: 0 },
        }
    },
    methods: {
        cancel() {
            this.$router.back();
        },
        async addObj() {
            var d = new Date(this.transaction.date);
            if (!(d instanceof Date) || isNaN(d)) {
                alert("Select valid bill date");
                return;
            }
            if (this.transaction.cust == null) {
                alert("Select customer name");
                return;
            }
            if (isNaN(this.transaction.amount)) {
                alert("Enter valid amount");
                return;
            }
            var data;
            if (this.$route.params.id) {
                //check if cust is change and new amount is less then utilized amount.
                if (this.transaction.amount < this.transaction.utilized_amount) {
                    alert("New amount (" + this.transaction.amount + ") is lesser than total amount linked (" + this.transaction.utilized_amount + "). Please remove linked payments before retrying.");
                    return;
                }

                if (this.original.entity_id != this.transaction.entity_id) {
                    var res = confirm("You have changed customer. This will remove all linked payments and this action is irreversible. \nWould you still like to continue?");
                    if (!res) {
                        return;
                    }
                }

                data = await commonMixin.methods.callPost('transaction/' + this.$route.params.id, this.transaction);
                commonMixin.methods.showToast(data.message);
            } else {
                data = await commonMixin.methods.callPost('transaction', this.transaction);
                commonMixin.methods.showToast(data.message);
            }
            if (data.status != "0") {
                return;
            }
            this.$router.push({ name: 'TransactionMain' });
        },
        custChanged(newCust) {
            this.transaction.cust = newCust;
            this.transaction.entity_id = newCust.id;
        },
        viewBill(id) {
            this.$router.push({ name: 'SaleInfo', params: { 'id': id } });
        },
        async getUpdateInfo() {
            var data = await commonMixin.methods.callGet("transaction/" + this.$route.params.id, "");
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.$router.push({ name: 'TransactionMain' });
                return;
            }
            this.transaction = data.obj;
            if (this.transaction.mode == "0" || this.transaction.mode == "4") {
                commonMixin.methods.showError("Update for this transaction is not allowed.");
                this.$router.push({ name: 'TransactionMain' });
                return;
            }
            for (var i = 0; i < this.custs.length; i++) {
                if (this.custs[i].id == this.transaction.entity_id) {
                    this.custChanged(this.custs[i]);
                    break;
                }
            }
            this.original.amount = this.transaction.amount;
            this.original.entity_id = this.transaction.entity_id;
        },
    },
    async mounted() {
        this.tempCusts = await (await commonMixin.methods.callGet("entity/getAllEntites", "")).obj;
        this.custs = [];
        if (this.$route.params.id) {
            this.heading = "Update";
            await this.getUpdateInfo();
            for (let i = 0; i < this.tempCusts.length; i++) {
                console.log(this.transaction.entity_id)
                if (this.tempCusts[i].id == this.transaction.entity_id) {
                    this.custChanged(this.tempCusts[i]);
                    if (this.tempCusts[i].deleted_at != null) {
                        this.custs.push(this.tempCusts[i]);
                    }
                    break;
                }
            }
        } else {
            this.heading = "Add";
        }
        for (let i = 0; i < this.tempCusts.length; i++) {
            if (this.tempCusts[i].deleted_at == null) {
                this.custs.push(this.tempCusts[i]);
            }
        }
    }
}
</script>