import { createWebHashHistory, createRouter } from "vue-router";

import Dashboard from "./components/Dashboard/Dashboard";

import Item from "./components/Item/Item";
import ItemMain from "./components/Item/ItemMain";
import ItemInfo from "./components/Item/ItemInfo";
import ItemAddUpdate from "./components/Item/ItemAddUpdate";

import Customer from "./components/Customer/Customer";
import CustomerMain from "./components/Customer/CustomerMain";
import CustomerInfo from "./components/Customer/CustomerInfo";
import CustomerAddUpdate from "./components/Customer/CustomerAddUpdate";


import Sale from "./components/Sale/Sale";
import SaleMain from "./components/Sale/SaleMain";
import SaleInfo from "./components/Sale/SaleInfo";
import SaleAddUpdate from "./components/Sale/SaleAddUpdate";
import SalePrint from "./components/Sale/SalePrint";

import Transaction from "./components/Transaction/Transaction";
import TransactionMain from "./components/Transaction/TransactionMain";
import TransactionInfo from "./components/Transaction/TransactionInfo";
import TransactionAddUpdate from "./components/Transaction/TransactionAddUpdate";


import User from "./components/User/User";

import Log from "./components/Log/Log";

import Login from "./components/Login";
import Home from "./components/Home"

const routes=[
    {
        name: "Home",
        path: "/",
        component: Home,
        children: [
            {
                name: "Dashboard",
                path: "",
                component: Dashboard
            },
            {
                name: "Item",
                path: "item",
                component: Item,
                children: [
                    {
                        name: "ItemMain",
                        path: "",
                        component: ItemMain
                    },
                    {
                        name: "ItemInfo",
                        path: ":id",
                        component: ItemInfo
                    },
                    {
                        name: "ItemAdd",
                        path: "add",
                        component: ItemAddUpdate
                    },
                    {
                        name: "ItemUpdate",
                        path: "update/:id",
                        component: ItemAddUpdate
                    }
                ]
            },
            {
                name: "Customer",
                path: "customer",
                component: Customer,
                children: [
                    {
                        name: "CustomerMain",
                        path: "",
                        component: CustomerMain
                    },
                    {
                        name: "CustomerInfo",
                        path: ":id",
                        component: CustomerInfo
                    },
                    {
                        name: "CustomerAdd",
                        path: "add",
                        component: CustomerAddUpdate
                    },
                    {
                        name: "CustomerUpdate",
                        path: "update/:id",
                        component: CustomerAddUpdate
                    }
                ]
            },
            {
                name: "Sale",
                path: "sale",
                component: Sale,
                children: [
                    {
                        name: "SaleMain",
                        path: "",
                        component: SaleMain
                    },
                    {
                        name: "SaleInfo",
                        path: ":id",
                        component: SaleInfo
                    },
                    {
                        name: "SaleAdd",
                        path: "add",
                        component: SaleAddUpdate
                    },
                    {
                        name: "SaleUpdate",
                        path: "update/:id",
                        component: SaleAddUpdate
                    },
                    {
                        name: "SalePrint",
                        path: "print",
                        component: SalePrint
                    }
                ]
            },
            {
                name: "Transaction",
                path: "transaction",
                component: Transaction,
                children: [
                    {
                        name: "TransactionMain",
                        path: "",
                        component: TransactionMain
                    },
                    {
                        name: "TransactionInfo",
                        path: ":id",
                        component: TransactionInfo
                    },
                    {
                        name: "TransactionAdd",
                        path: "add",
                        component: TransactionAddUpdate
                    },
                    {
                        name: "TransactionUpdate",
                        path: "update/:id",
                        component: TransactionAddUpdate
                    }
                ]
            },
            {
                path: "/User",
                component: User
            },
            {
                path: "/Log",
                component: Log
            }
        ]
    },
    {
        name: "Login",
        path: "/login",
        component: Login
    },
];

const router=createRouter({
    history:createWebHashHistory(),
    routes
});
export default router;