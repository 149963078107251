<template>

    <div class="row" v-if="showInfo">
        <div class="left" style="margin-top: 0.2em;">
            <div class="row">
                <div class="col-4">
                    <button type="button" class="btn btn-link" @click="goBack()"><span
                            class="bi bi-arrow-left-circle" /> Go
                        back</button>
                </div>
                <div class="col-8" style="text-align: right;">

                    <button type="button" title="Add new" class="btn btn-light" @click="addNew()"
                        style="margin-right: 0.2em;"><i class="bi bi-plus-square link-success"></i></button>

                    <button type="button" title="Update" class="btn btn-light" @click="editObj(transaction.id)"
                        style="margin-right: 0.2em;" v-if="transaction.mode != 0 && transaction.mode != 4"><i class="bi bi-pencil link-warning"></i></button>

                    <button type="button" title="Remove" class="btn btn-light" @click="deleteObj(transaction.id)"><i
                            class="bi bi-trash link-danger" v-if="transaction.mode != 0 && transaction.mode != 4"></i></button>
                </div>
            </div>
        </div>
        <h1 class="display-6 left" v-if="showInfo">{{ transaction.entityName }}</h1>
        <br>
    </div>

    <div class="row" v-if="showInfo">
        <div class="col-md-6">
            <div class="left info">
                <table class="table table-striped">
                    <tbody>

                        <tr v-for="k in Object.keys(obj)" v-bind:key="k">
                            <th>
                                {{ k }}:
                            </th>
                            <td v-if="k != 'Utilized Status'">
                                {{ obj[k] }}
                            </td>
                            <td v-else>
                                <span class="badge bg-success"
                                    v-if="transaction.amount == transaction.utilized_amount">Fully</span>
                                <span class="badge bg-warning"
                                    v-else-if="transaction.utilized_amount != 0">Partially</span>
                                <span class="badge bg-danger" v-else>Not at all</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="col-md-6">
            <h1 style="font-size: calc(1.375rem + 0.5vw) !important" class="display-6 left">Linked bills</h1>
            <table class="table table-striped table-hover text-nowrap pointer" v-if="transaction.links.length > 0">
                <thead class="table-dark">
                    <tr>
                        <th>Bill ID</th>
                        <th>Bill Date</th>
                        <th>Bill Amount</th>
                        <th>Remove</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="link in transaction.links" v-bind:key="link.id">
                        <td @click="viewBill(link.bill_id)">{{ link.bill_id }}</td>
                        <td>{{ link.bill_date }}</td>
                        <td class="right">₹ {{ parseFloat(link.amount).toFixed(2) }}</td>
                        <td>
                            <button type="button" title="Remove" class="btn btn-light" @click="deleteLink(link)"><i
                                    class="bi bi-trash link-danger"></i></button>
                        </td>
                    </tr>

                </tbody>
            </table>

            <button type="button" class="btn btn-link" @click="addLink()" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop" v-if="transaction.amount != transaction.utilized_amount"><span
                    class="bi bi-plus-square link-primary" />
                Add link</button>

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header bg-dark">
                            <h5 class="modal-title" style="color: white" id="staticBackdropLabel">Add Payment Link</h5>
                            <button ref="closeModal" type="button" data-bs-dismiss="modal">X</button>
                        </div>
                        <div class="modal-body">


                            <div class="row">
                                <label class="col-sm-12 col-form-label left"><b>Maximum Amount can alloted:
                                        ₹ {{ parseFloat(transaction.amount -
                                                transaction.utilized_amount).toFixed(2)
                                        }}</b></label>
                            </div>

                            <div class="row">

                                <div class="row mb-12">
                                    <label class="col-sm-6 col-form-label left">Bill</label>
                                    <div class="col-sm-6">

                                        <div class="dropdown left">
                                            <button class="btn left" required type="button" data-bs-toggle="dropdown"
                                                style="width: 100%; border: 1px solid gray; cursor: pointer;" readonly>
                                                <span v-if="selectedBill == null">
                                                    Select bill
                                                </span>

                                                <span v-if="selectedBill != null">
                                                    {{ selectedBill.id + " - " + selectedBill.bill_date }}
                                                </span>
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2"
                                                style="width: 100%">
                                                <li v-for="bill in bills" v-bind:key="bill.id"
                                                    @click="billChanged(bill)">
                                                    <div class="dropdown-item">
                                                        {{ bill.id + " - " + bill.bill_date }}
                                                        <span style="float: right">
                                                            ₹ {{ bill.total - bill.received }}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div class="row" v-if="selectedBill != null">
                                    <label class="col-sm-6 col-form-label left">Pending amount</label>
                                    <div class="col-sm-6">
                                        <input disabled readonly type="text" class="form-control"
                                            :value="selectedBill.total - selectedBill.received">
                                    </div>
                                </div>

                                <div class="row" v-if="selectedBill != null">
                                    <label class="col-sm-6 col-form-label left">Amount (max. ₹ {{ maxAmount }})</label>
                                    <div class="col-sm-6">
                                        <input type="text" id="amount" class="form-control"
                                            @keydown.enter="linkTransaction()" v-model="selectedBill.newAmount">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="linkTransaction()">Link</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-transaction-info',
    data() {
        return {
            transaction: {},
            obj: {},
            showInfo: false,
            bills: [],
            selectedBill: null,
        }
    },
    components: {
    },
    methods: {

        addNew() {
            this.$router.push({ name: 'TransactionAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'TransactionUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('transaction/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.$router.push({ name: 'TransactionMain' });
            }
        },
        async addLink() {
            this.selectedBill = null;
            var objs = await (await commonMixin.methods.callGet("sale", "")).obj;
            this.bills = [];
            for (var i = 0; i < objs.length; i++) {
                if (objs[i].total > objs[i].received && objs[i].entity_id == this.transaction.entity_id) {
                    this.bills.push(objs[i]);
                }
            }
        },
        async linkTransaction() {
            if (this.selectedBill == null) {
                alert("Please select bill you want to link.");
                return;
            }
            if (isNaN(this.selectedBill.newAmount) || this.selectedBill.newAmount == "") {
                alert("Please enter valid amount.");
                return;
            }
            if (this.selectedBill.newAmount > this.maxAmount) {
                alert("Please enter amount less than or equal to " + this.maxAmount);
                return;
            }
            this.$refs.closeModal.click();

            var newObj = {};
            newObj.transaction_id = this.transaction.id;
            newObj.bill_id = this.selectedBill.id;
            newObj.amount = this.selectedBill.newAmount;

            var data = await commonMixin.methods.callPost('transaction/link/add', newObj);
            commonMixin.methods.showToast(data.message);
            if (data.status == 0) {
                this.$router.go();
            }
        },
        billChanged(newBill) {
            this.selectedBill = newBill;
            this.selectedBill.newAmount = this.maxAmount;
            setTimeout(function () {
                document.getElementById("amount").focus(); 
                document.getElementById("amount").select(); 
            }, 500);
        },
        async deleteLink(link) {
            console.log(link);
            var res = confirm("Are you sure you want to delete this payment link?");
            if (res) {
                var data = await commonMixin.methods.callPost('transaction/link/delete', link);
                commonMixin.methods.showToast(data.message);
                if (data.status == 0) {
                    this.$router.go();
                }
            }
        },
        viewBill(id) {
            this.$router.push({ name: 'SaleInfo', params: { 'id': id } });
        },
        goBack() {
            this.$router.back();
        },

    },
    computed: {
        maxAmount: function () {
            if ((this.selectedBill.total - this.selectedBill.received) < (this.transaction.amount - this.transaction.utilized_amount)) {
                return this.selectedBill.total - this.selectedBill.received
            } else {
                return this.transaction.amount - this.transaction.utilized_amount;
            }
        },
    },
    async mounted() {
        var data = await commonMixin.methods.callGet("transaction/" + this.$route.params.id, "");
        if (data.status == "-1") {
            commonMixin.methods.showError(data.message);
            this.showInfo = false;
            return;
        }
        this.transaction = data.obj;
        this.showInfo = true;

        this.obj["ID"] = this.transaction.id;
        this.obj["Customer Name"] = this.transaction.entityName;
        this.obj["Date"] = this.transaction.date;
        this.obj["Amount"] = parseFloat(this.transaction.amount).toFixed(2);
        this.obj["Utilized amount"] = parseFloat(this.transaction.utilized_amount).toFixed(2);
        this.obj["Utilized Status"] = "";

        if (this.transaction.type == 0) {
            this.obj["Type"] = "Paid";
        } else if (this.transaction.type == 1) {
            this.obj["Type"] = "Received";
        }

        if (this.transaction.mode == 0) {
            this.obj["Mode"] = "Opening balance";
            if (this.transaction.type == 0) {
                this.obj["Type"] = "Receivable";
            } else if (this.transaction.type == 1) {
                this.obj["Type"] = "Payable";
            }
        } else if (this.transaction.mode == 1) {
            this.obj["Mode"] = "Cash";
        } else if (this.transaction.mode == 2) {
            this.obj["Mode"] = "Cheque";
        } else if (this.transaction.mode == 3) {
            this.obj["Mode"] = "UPI / Online";
        }
        this.obj["Payment details"] = this.transaction.payment_details;
        this.obj["Note"] = this.transaction.note;

        this.obj["Created"] = commonMixin.methods.getConvertedDate(this.transaction.created_at);
        this.obj["Last updated"] = commonMixin.methods.getConvertedDate(this.transaction.updated_at);
    }
}
</script>
