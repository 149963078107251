<template>
    <br>
    <h1>Logs</h1>
    <br>
    <div class="container" v-if="showInfo">

        <div class="container" v-if="user.isAdmin">
            <div class="row col-sm-6 left">
                <label class="form-label">Select User: </label>
                <select class="form-select" v-model="selectedUserId" @change="userChanged()">
                    <option v-for="u in users" v-bind:key="u.id" v-bind:value="u.id">{{
                        u.name
                    }}
                    </option>
                </select>

            </div>


            <div class="" style="overflow-x: auto">
                <table class="table table-striped table-hover text-nowrap pointer">
                    <thead class="table-dark">
                        <tr>
                            <th>User</th>
                            <th>Module</th>
                            <th>Action</th>
                            <th>ID</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="logs.length == 0">
                            <td colspan="8">No data found.</td>
                        </tr>
                        <tr v-for="log in logs" v-bind:key="log.id" @click="getInfo(log.id)">
                            <td> {{ user.name }}</td>
                            <td>
                                <span v-if="log.module == 1">Item</span>
                                <span v-else-if="log.module == 2">Customer</span>
                                <span v-else-if="log.module == 3">Sale</span>
                                <span v-else-if="log.module == 4">Transaction</span>
                            </td>
                            <td>
                                <span v-if="log.action == 0">Created</span>
                                <span v-else-if="log.action == 1">Read</span>
                                <span v-else-if="log.action == 2">Updated</span>
                                <span v-else-if="log.action == 3">Deleted</span>
                                <span v-else-if="log.action == 4">Logged in</span>
                                <span v-else-if="log.action == 5">Logged out</span>
                            </td>
                            <td>
                                {{ log.foreign_id }}
                            </td>
                            <td>
                                {{ getConvertedDate(log.created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <div class="container" v-if="!user.isAdmin">
            <h2 class="text-danger">You don't have access to this.</h2>
        </div>

    </div>

</template>
    
<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-log',
    data() {
        return {
            user: {},
            users: [],
            logs: [],
            selectedUserId: {},
            showInfo: false,
        }
    },
    methods: {
        async userChanged() {
            console.log(this.selectedUserId);
            var data = await commonMixin.methods.callPost("log/getByUser/" + this.selectedUserId);
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                return;
            }
            this.logs = data.obj;
        },
        getConvertedDate(date) {
            return commonMixin.methods.getConvertedDate(date);
        },
    },
    async mounted() {
        var data = await commonMixin.methods.callPost("user/get");
        if (data.status == "-1") {
            commonMixin.methods.showError(data.message);
            this.$router.push({ name: 'ItemMain' });
            return;
        }
        this.user = await data;
        this.showInfo = true;

        data = await commonMixin.methods.callPost("user/getAll");
        this.users = data.obj;
    }
}
</script>