<template>

    <div class="row" v-if="showInfo">
        <div class="left" style="margin-top: 0.2em;">
            <div class="row">
                <div class="col-4">
                    <button type="button" class="btn btn-link" @click="goBack()"><span
                            class="bi bi-arrow-left-circle" /> Go
                        back</button>
                </div>
                <div class="col-8" style="text-align: right;">

                    <button type="button" title="Add new" class="btn btn-light" @click="addNew()"
                        style="margin-right: 0.2em;"><i class="bi bi-plus-square link-success"></i></button>

                    <button type="button" title="Update" class="btn btn-light" @click="editObj(sale.id)"
                        style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>

                    <button type="button" title="Remove" class="btn btn-light" @click="deleteObj(sale.id)"><i
                            class="bi bi-trash link-danger"></i></button>
                </div>
            </div>

            <div class="row">

                <div class="col-12" style="text-align: right;">
                    <button type="button" title="Print" class="btn btn-light" @click="printSale()"><i
                            class="bi bi-printer link-primary"></i> Print</button>
                </div>
            </div>

        </div>
        <br>

    </div>

    <div class="container" v-if="showInfo">
        <div class="row left">
            <h3>{{ sale.entity_name }}</h3>
        </div>
        <div class="row">
            <div class="col-sm-6 left">

                <div class="row">
                    <label class="form-label left" style="white-space: pre-wrap"><b>Addess:</b><br>{{ sale.address
                    }}</label>
                </div>

                <div class="row" v-if="sale.gstin != null">
                    <label class="form-label left"><b>GSTIN:</b> {{ sale.gstin }}</label>
                </div>

            </div>

            <div class="col-sm-6 left">
                <div class="row">
                    <label class="form-label left"><b>Date: </b>{{ sale.bill_date }}</label>
                </div>

                <div class="row" v-if="sale.remark != null">
                    <label class="form-label left"><b>Remark:</b> {{ sale.remark }}</label>
                </div>

                <div class="row">
                    <form>
                        <input class="form-check-input" @change="taxIncludedChanged()" v-model="sale.rate_including_tax"
                            type="checkbox" onclick="return false" onkeydown="return false;">
                        <label class="form-check-label" for="inlineCheckbox1" style="margin-left: 0.5em;"> <b>Tax
                                included</b></label>
                    </form>
                </div>
            </div>

            <hr>

            <div class="row" v-if="sale.items.length > 0">
                <table class="table table-hover text-nowrap pointer col-12">
                    <thead class="thead-dark table-dark saleThead">
                        <tr>
                            <th class="fitContent left">Sr.<br>No.</th>
                            <th class="left">Description</th>
                            <th class="fitContent left">HSN</th>
                            <th class="fitContent">Qty</th>
                            <th class="fitContent">Rate</th>
                            <th class="fitContent">Taxable<br />Amount</th>
                            <th class="fitContent">GST</th>
                            <th class="fitContent">Total</th>
                        </tr>
                    </thead>
                    <tbody style="border: 1px black solid;">
                        <tr class="billItemRow" v-for="(item, index) in sale.items" v-bind:key="item.id">
                            <td>{{ index + 1 }}</td>
                            <td class="left"><span>{{ item.detail.name }}</span>
                                <p class="text-muted" style="font-size: smaller">{{ item.comment }}</p>
                            </td>
                            <td class="right">{{ item.item.barcode }}</td>
                            <td class="right">{{ item.qty }}</td>
                            <td class="right">{{ item.detail.rate }}</td>
                            <td class="right">{{ item.detail.taxableAmount }}</td>
                            <td class="right">{{ item.detail.gst }} <p class="text-muted" style="font-size: smaller">
                                    {{ item.tax_percentage }}%</p>
                            </td>
                            <td class="right">{{ item.detail.amount }}</td>
                        </tr>
                    </tbody>
                    <thead class="thead-dark table-dark saleThead">
                        <tr>
                            <th colspan="3" class="right">Total</th>
                            <th>{{ totalQty }}</th>
                            <th></th>
                            <th></th>
                            <th>{{ totalTax }}</th>
                            <th>{{ parseFloat(sale.total - this.sale.adjustment).toFixed(2) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="6" class="right">Total amount before tax:</td>
                            <th colspan="2" class="right">{{ this.totalTaxable }}</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="right">Total tax:</td>
                            <th colspan="2" class="right">{{ this.totalTax }}</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="right">Adjustment:</td>
                            <th colspan="2" class="right">{{ this.sale.adjustment }}</th>
                        </tr>
                        <tr>
                            <td colspan="6" class="right">Total amount:</td>
                            <th colspan="2" class="right">{{ this.sale.total }}</th>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div class="container" v-if="sale.payment_links != null && sale.payment_links.length > 0">
                <div class="col-md-4 col-12">
                    <div class="row">
                        <h1 style="font-size: calc(1.375rem + 0.2vw) !important" class="display-6 left">Payment
                            details
                        </h1>
                        <hr>
                        <div v-for="link in sale.payment_links" v-bind:key="link.id">
                            <label class="col-md-6 col-6 col-form-label left"><b>
                                    <span v-if="link.payment_type == 0">Opening balance</span>
                                    <span v-else-if="link.payment_type == 1">Cash</span>
                                    <span v-else-if="link.payment_type == 2">Cheque</span>
                                    <span v-else-if="link.payment_type == 3">UPI / Online</span>

                                </b>
                            </label>
                            <label class="col-md-6 col-6 col-form-label right">₹ {{
                                    parseFloat(link.received_amount).toFixed(2)
                            }}</label>
                        </div>
                        <hr>
                        <div>
                            <label class="col-md-6 col-6 col-form-label left"><b>Total</b></label>
                            <label class="col-md-6 col-6 col-form-label right">₹ {{
                                    parseFloat(sale.received_amount).toFixed(2)
                            }}</label>
                        </div>
                        <hr>
                        <div v-if="parseFloat(sale.total) - parseFloat(sale.received_amount) != 0">
                            <label class="col-md-6 col-6 col-form-label left"><b>Pending amount</b></label>
                            <label class="col-md-6 col-6 col-form-label right">₹ {{
                                    parseFloat(parseFloat(sale.total) - parseFloat(sale.received_amount)).toFixed(2)
                            }}</label>
                        </div>
                        <div>
                            <button type="button" class="btn btn-link" data-bs-toggle="collapse"
                                data-bs-target="#viewTransactions">
                                View all payment transactions</button>

                        </div>
                    </div>
                </div>

            </div>

            <button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#allTransactions"
                    v-if="parseFloat(sale.total) - parseFloat(sale.received_amount) != 0" @click="addPayment()">
                    Link payment transactions</button>

            <div id="viewTransactions" class="collapse">
                <table class="table table-striped text-nowrap ">
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th class="left">Mode</th>
                        <th class="left">Details / Note</th>
                        <th class="right">Amount</th>
                        <th>Remove</th>
                    </tr>
                    <tbody>
                        <tr v-for="link in sale.payment_all_links" v-bind:key="link.id">
                            <td>{{ link.id }}</td>
                            <td>{{ link.date }}</td>
                            <td class="left">
                                <span v-if="link.payment_type == 0">Opening balance</span>
                                <span v-else-if="link.payment_type == 1">Cash</span>
                                <span v-else-if="link.payment_type == 2">Cheque</span>
                                <span v-else-if="link.payment_type == 3">UPI / Online</span>
                            </td>
                            <td class="left">
                                <span v-if="link.payment_details == null || link.payment_details == ''">-
                                </span>
                                <span v-else>{{ link.payment_details }}</span>
                                /
                                <span v-if="link.note == null || link.note == ''"> -</span>
                                <span v-else>{{ link.note }}</span>
                            </td>
                            <td class="right">₹ {{ parseFloat(link.received_amount).toFixed(2)
                            }}</td>
                            <td>
                                <button type="button" title="Remove" class="btn btn-light" @click="deleteLink(link)"><i
                                        class="bi bi-trash link-danger"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal fade" id="allTransactions" data-bs-backdrop="static" data-bs-keyboard="false"
                tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header bg-dark">
                            <h5 class="modal-title" style="color: white" id="staticBackdropLabel">Payment
                                transactions
                            </h5>
                            <button ref="closeModal" type="button" data-bs-dismiss="modal">X</button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <label class="col-sm-12 col-form-label left"><b>Pending amount:
                                        ₹ {{ parseFloat(parseFloat(sale.total) -
                                                parseFloat(sale.received_amount)).toFixed(2)
                                        }}</b></label>
                                <div><u><i><b>Click on transaction to select</b></i></U></div>
                                <table class="table table-striped table-hover text-nowrap pointer">
                                    <tr>
                                        <th>ID</th>
                                        <th>Date</th>
                                        <th class="left">Mode</th>
                                        <th class="left">Details / Note</th>
                                        <th class="right">Pending Amount</th>
                                    </tr>
                                    <tbody>
                                        <tr v-for="link in transactions" v-bind:key="link.id"
                                            @click="selectTransaction(link)"
                                            v-bind:class="{ 'selectedRow': selectedTransaction == link }">
                                            <td>{{ link.id }}</td>
                                            <td>{{ link.date }}</td>
                                            <td class="left">
                                                <span v-if="link.payment_type == 0">Opening balance</span>
                                                <span v-else-if="link.payment_type == 1">Cash</span>
                                                <span v-else-if="link.payment_type == 2">Cheque</span>
                                                <span v-else-if="link.payment_type == 3">UPI / Online</span>
                                            </td>
                                            <td class="left">
                                                <span
                                                    v-if="link.payment_details == null || link.payment_details == ''">-
                                                </span>
                                                <span v-else>{{ link.payment_details }}</span>
                                                /
                                                <span v-if="link.note == null || link.note == ''"> -</span>
                                                <span v-else>{{ link.note }}</span>
                                            </td>
                                            <td class="right">₹ {{ parseFloat(parseFloat(link.amount) -
                                                    parseFloat(link.utilized_amount)).toFixed(2)
                                            }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <div class="row" v-if="selectedTransaction != null">

                                    <label class="col-md-6 col-form-label left"><b>Amount (max. ₹ {{ maxAmount
                                    }})</b></label>
                                    <div class="col-md-6">
                                        <input type="text" id="amount" class="form-control col-md-6"
                                            @keydown.enter="linkTransaction()" v-model="selectedTransaction.newAmount">
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" v-if="selectedTransaction != null"
                                @click="linkTransaction()">Link</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-sale-info',
    data() {
        return {
            sale: {},
            showInfo: false,
            items: [],
            selectedTransaction: null,
            transactions: [],
            hideWhilePrinting: true,
        }
    },
    components: {
    },
    methods: {

        addNew() {
            this.$router.push({ name: 'SaleAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'SaleUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('sale/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.$router.push({ name: 'SaleMain' });
            }
        },
        viewBill(id) {
            this.$router.push({ name: 'SaleInfo', params: { 'id': id } });
        },
        goBack() {
            this.$router.back();
        },
        async addPayment() {
            this.selectedTransaction = null;
            this.transactions = await (await commonMixin.methods.callGet("transaction/entity/" + this.sale.entity_id + "/pending", "")).obj;
        },
        selectTransaction(transaction) {
            this.selectedTransaction = transaction;
            this.selectedTransaction.newAmount = this.maxAmount;
            setTimeout(function () {
                document.getElementById("amount").focus();
                document.getElementById("amount").select();
            }, 500);
        },
        async deleteLink(link) {
            console.log(link);
            var res = confirm("Are you sure you want to delete this payment link?");
            if (res) {
                var data = await commonMixin.methods.callPost('transaction/link/delete', link);
                commonMixin.methods.showToast(data.message);
                if (data.status == 0) {
                    this.$router.go();
                }
            }
        },
        async linkTransaction() {
            if (this.selectedTransaction == null) {
                alert("Please select transaction you want to link.");
                return;
            }
            if (isNaN(this.selectedTransaction.newAmount) || this.selectedTransaction.newAmount == "") {
                alert("Please enter valid amount.");
                return;
            }
            if (this.selectedTransaction.newAmount > this.maxAmount) {
                alert("Please enter amount less than or equal to " + this.maxAmount);
                return;
            }
            this.$refs.closeModal.click();

            var newObj = {};
            newObj.transaction_id = this.selectedTransaction.id;
            newObj.bill_id = this.sale.id;
            newObj.amount = this.selectedTransaction.newAmount;

            var data = await commonMixin.methods.callPost('transaction/link/add', newObj);
            commonMixin.methods.showToast(data.message);
            if (data.status == 0) {
                this.$router.go();
            }
        },

        addItemDetails(item) {
            //Create display name
            item.detail = {}
            let obj = item.item;

            var name = obj.name + " - " + obj.weight_in_grms + "g ";
            name += obj.package_type == null ? "" : "(" + obj.package_type + ")"
            item.detail.name = name;

            //Calc amount
            if (this.sale.rate_including_tax) {
                item.detail.rate = (item.rate * 100) / (100 + parseFloat(item.tax_percentage));
            } else {
                item.detail.rate = item.rate;
            }
            item.detail.taxableAmount = item.detail.rate * item.qty;
            item.detail.gst = (item.tax_percentage * item.detail.taxableAmount) / 100;
            item.detail.amount = parseFloat(item.detail.gst) + parseFloat(item.detail.taxableAmount);

            item.detail.rate = parseFloat(item.detail.rate).toFixed(2);
            item.detail.taxableAmount = parseFloat(item.detail.taxableAmount).toFixed(2);
            item.detail.gst = parseFloat(item.detail.gst).toFixed(2);
            item.detail.amount = parseFloat(item.detail.amount).toFixed(2);
        },
        calculateTotal() {
            this.sale.total = 0;
            this.totalTax = 0;
            this.totalQty = 0;
            this.totalTaxable = 0;
            for (var i = 0; i < this.sale.items.length; i++) {
                this.sale.total = parseFloat(this.sale.total) + parseFloat(this.sale.items[i].detail.amount);
                this.totalTax = parseFloat(this.totalTax) + parseFloat(this.sale.items[i].detail.gst);
                this.totalTaxable = parseFloat(this.totalTaxable) + parseFloat(this.sale.items[i].detail.taxableAmount);
                this.totalQty = parseFloat(this.sale.items[i].qty) + parseFloat(this.totalQty);
            }
            this.sale.total = parseFloat(this.sale.total).toFixed(2);
            this.totalTax = parseFloat(this.totalTax).toFixed(2);
            this.totalTaxable = parseFloat(this.totalTaxable).toFixed(2);
            this.sale.adjustment = parseFloat( Math.round(this.sale.total) - this.sale.total ).toFixed(2);
            this.sale.total = parseFloat(Math.round(this.sale.total)).toFixed(2);
            this.sale.totalQty = this.totalQty;

        },
        taxIncludedChanged() {
            for (var i = 0; i < this.sale.items.length; i++) {
                this.addItemDetails(this.sale.items[i]);
            }
            this.calculateTotal();
        },
        calculatePaymentTotal() {
            this.sale.received_amount = 0;
            for (var i = 0; i < this.sale.payment_links.length; i++) {
                this.sale.received_amount = parseFloat(this.sale.payment_links[i].received_amount) + parseFloat(this.sale.received_amount);
            }
        },
        printSale() {
            this.sale.totalTaxable = this.totalTaxable;
            this.sale.totalTax = this.totalTax;
            this.$router.push({ name: 'SalePrint', params: { sale: JSON.stringify(this.sale) } })
        }
    },
    computed: {
        maxAmount: function () {
            if ((this.selectedTransaction.amount - this.selectedTransaction.utilized_amount) < (this.sale.total - this.sale.received_amount)) {
                return this.selectedTransaction.amount - this.selectedTransaction.utilized_amount
            } else {
                return this.sale.total - this.sale.received_amount;
            }
        },
    },
    async mounted() {
        var data = await commonMixin.methods.callGet("sale/" + this.$route.params.id, "");
        if (data.status == "-1") {
            commonMixin.methods.showError(data.message);
            this.showInfo = false;
            return;
        }
        this.sale = data.obj;

        this.sale.rate_including_tax = this.sale.rate_including_tax == 1 ? true : false;
        this.taxIncludedChanged();
        this.calculatePaymentTotal();
        this.showInfo = true;
        if (this.$route.params.isPrint) {
            this.printSale();
        }
    }
}
</script>
<style>
.selectedRow {
    background-color: gray;
}
.saleThead tr th {
  border: 1px solid;
}

</style>
