<template>
    <div>
        <br />
        <h1 class="display-6 left">{{heading}}</h1>
        <br />
        <form>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" :class="[this.currentNav == 'basic' ? 'active' : '']" aria-current="page"
                        @click="this.currentNav='basic'">Basic Details</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="[this.currentNav == 'address' ? 'active' : '']" aria-current="page"
                        @click="this.currentNav='address'">Address</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="[this.currentNav == 'bank' ? 'active' : '']" aria-current="page"
                        @click="this.currentNav='bank'">Bank Details</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="[this.currentNav == 'openingBal' ? 'active' : '']" aria-current="page"
                        @click="this.currentNav='openingBal'">Opening Balance</a>
                </li>
            </ul>

            <br />
            <div class="container" v-if="this.currentNav == 'basic'">
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Name</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.name">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Contact person</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.contact_person">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Mobile</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.mobile">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Phone</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.phone">
                    </div>
                </div>


                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Email</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.email">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Website</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.website">
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Remark</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" @keydown.tab="next()" v-model="cust.remark">
                    </div>
                </div>

            </div>

            <div class="container" v-if="this.currentNav == 'address'">

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Address</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" @keydown.shift.tab="previous()" v-model="cust.address">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">City</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.city">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Pin</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.pin">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">State</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" @keydown.tab="next()" v-model="cust.state">
                    </div>
                </div>

            </div>

            <div class="container" v-if="this.currentNav == 'bank'">


                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">GSTIN</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" @keydown.shift.tab="previous()" v-model="cust.GSTIN">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">PAN</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.PAN">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Bank A/c No</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.bank_account_no">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Bank IFSC</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.bank_ifsc">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Branch</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" @keydown.tab="next()" v-model="cust.branch">
                    </div>
                </div>
            </div>

            <div class="container" v-if="this.currentNav == 'openingBal'">
                <div class="row mb-3" style="display: none">
                    <label class="col-sm-3 col-form-label left">Date</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.transaction.date">
                    </div>
                </div>


                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Amount</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" @keydown.shift.tab="previous()"
                            v-model="cust.transaction.amount">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Type</label>
                    <div class="col-sm-6">
                        <select class="form-select" v-model="cust.transaction.type">
                            <option value="0">Receivable</option>
                            <option value="1">Payable</option>
                        </select>
                    </div>
                </div>


                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Payment details</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.transaction.payment_details">
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label left">Note</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" v-model="cust.transaction.note">
                    </div>
                </div>
            </div>

            <div style="text-align: left; position: absolute">
                <button type="button" class="btn btn-primary" v-if="currentNav != 'basic'"
                    @click.prevent="previous()">Previous</button>
            </div>

            <button type="button" class="btn btn-link text-danger" @click.prevent="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" v-if="currentNav == 'openingBal'"
                @click.prevent="addCust()">Submit</button>

            <div style="float: right;">
                <button type="button" class="btn btn-primary" v-if="currentNav != 'openingBal'"
                    @click.prevent="next()">Next</button>
            </div>
        </form>
    </div>
</template>

<script>

import { commonMixin } from '@/js/common';

export default {
    name: 'app-customer-add-update',
    data() {
        return {
            cust: { transaction: { amount: 0, type: 0, payment_details: "", note: "", date: "" } },
            heading: "",
            tabs: ['basic', 'address', 'bank', 'openingBal'],
            currentNav: 'basic',
        }
    },
    methods: {
        cancel() {
            this.$router.back();
        },
        async addCust() {
            if (this.cust.name == "" || this.cust.name == null) {
                alert("Customer name can't be empty.");
                return;
            }
            if (isNaN(this.cust.transaction.amount)) {
                alert("Enter valid balance amount.");
                return;
            }
            var data;
            if (this.$route.params.id) {
                data = await commonMixin.methods.callPost('entity/' + this.$route.params.id, this.cust);
                commonMixin.methods.showToast(data.message);
            } else {
                data = await commonMixin.methods.callPost('entity', this.cust);
                commonMixin.methods.showToast(data.message);
            }
            if (data.status != "0") {
                return;
            }
            this.$router.push({ name: 'CustomerMain' });
        },
        next() {
            this.currentNav = this.tabs[this.tabs.indexOf(this.currentNav) + 1];
        }
        ,
        previous() {
            this.currentNav = this.tabs[this.tabs.indexOf(this.currentNav) - 1];
        }
    },
    async mounted() {
        if (this.$route.params.id) {
            this.heading = "Update";
            console.log(this.$route.params.id);
            var data = await commonMixin.methods.callGet("entity/" + this.$route.params.id, "");
            if (data.status == "-1") {
                commonMixin.methods.showError(data.message);
                this.$router.push({ name: 'CustomerMain' });
                return;
            }
            this.cust = data.obj;
            if (this.cust.transaction.id == -1) {
                const date = new Date();
                this.cust.transaction.date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            }
        } else {
            this.heading = "Add";
            const date = new Date();
            this.cust.transaction.date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        }
    }
}
</script>

<style>
.nav-tabs li {
    cursor: pointer;
}
</style>