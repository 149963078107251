<template>
<router-view></router-view>
</template>

<script>
export default {
    name: 'app-item',
    data() {
        return {
        }
    },
}
</script>
