<template>
    <br>
    <br>
    <h1>Transactions</h1>

    <div style="text-align: right; margin-bottom: 0.2em;">
        <button type="button" title="Add new" class="btn btn-light" @click="exportCSV()">
            <span class="bi bi-arrow-bar-up link-success"> Export</span></button>

        <button type="button" title="Add new" class="btn btn-light" @click="addNew()">
            <span class="bi bi-plus-square link-success"> Add New</span></button>
    </div>
    <div class="" style="overflow-x: auto">
        <table class="table table-striped table-hover text-nowrap pointer">
            <thead class="table-dark">
                <tr>
                    <th>ID</th>
                    <th class="left">Customer Name</th>
                    <th>Date</th>
                    <th>Payment mode</th>
                    <th class="right">Total Amount</th>
                    <th class="right">Utilized Amount</th>
                    <th class="right">Pending Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="objs.length == 0">
                    <td colspan="9">No data found.</td>
                </tr>
                <tr v-for="obj in filteredObjs" v-bind:key="obj.id" @click="getInfo(obj.id)">
                    <th scope="row">{{ obj.id }}</th>
                    <td class="left">{{ obj.entityName }}</td>
                    <td>{{ obj.date }}</td>
                    <td>
                        <span v-if="obj.mode == 0">Receivable (Opening balance)</span>
                        <span v-else-if="obj.mode == 1">Cash</span>
                        <span v-else-if="obj.mode == 2">Cheque</span>
                        <span v-else-if="obj.mode == 3">UPI / Online</span>
                        <span v-else-if="obj.mode == 4 && obj.type == 0">Receivable (Sales)</span>
                    </td>
                    <td class="right">₹ {{ parseFloat(obj.amount).toFixed(2) }}</td>
                    <td class="right">{{ (obj.mode == 0 || obj.mode == 4) ? "" : "₹ " +
                        parseFloat(obj.utilized_amount).toFixed(2) }}</td>
                    <td class="right">{{ (obj.mode == 0 || obj.mode == 4) ? "" : "₹ " +
                        parseFloat(parseFloat(obj.amount).toFixed(2) -
                            parseFloat(obj.utilized_amount).toFixed(2)).toFixed(2) }}</td>
                    <td>
                        <!-- <span class="badge bg-success" v-if="obj.amount == obj.utilized_amount">Fully</span>
                        <span class="badge bg-warning" v-else-if="obj.utilized_amount != 0">Partially</span>
                        <span class="badge bg-danger" v-else>Not at all</span> -->
                        <span style="border-radius: 10px" class="badge bg-success text-success" title="Fully"
                            v-if="obj.amount == obj.utilized_amount">.</span>
                        <span style="border-radius: 10px" class="badge bg-warning text-warning" title="Partially"
                            v-else-if="obj.utilized_amount != 0">.</span>
                        <span style="border-radius: 10px" v-else-if="obj.mode == 0 || obj.mode == 4"></span>
                        <span style="border-radius: 10px" class="badge bg-danger text-danger" title="Not at all"
                            v-else>.</span>

                    </td>
                    <td v-if="obj.mode == 0 || obj.mode == 4"></td>
                    <td @click.stop v-else>
                        <button type="button" title="Update transaction" class="btn btn-light" @click="editObj(obj.id)"
                            style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>
                        <button type="button" title="Remove transaction" class="btn btn-light" @click="deleteObj(obj.id)"><i
                                class="bi bi-trash link-danger"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-transaction-main',
    data() {
        return {
            objs: {},
        }
    },
    methods: {
        getInfo(id) {
            for (var i = 0; i < this.objs.length; i++) {
                if (this.objs[i].id == id) {
                    this.$router.push({ name: 'TransactionInfo', params: { "id": id } })
                    break;
                }
            }
        },
        addNew() {
            this.$router.push({ name: 'TransactionAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'TransactionUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('transaction/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.objs = await (await commonMixin.methods.callGet("transaction", "")).obj;
            }
        },
        exportCSV() {
            var rows = [
                ["id", "Customer name", "Date", "Mode", "Amount", "Utilized Amount", "Pending Amount"]
            ];
            for (var i = 0; i < this.objs.length; i++) {
                var obj = this.objs[i];
                // if(obj.mode == 4) {
                //     continue;
                // }
                var temp = [];
                temp.push(obj.id);
                temp.push(obj.entityName);
                temp.push(obj.date);
                if (obj.mode == "0") { temp.push("Receivable (Opening balance)"); } else
                    if (obj.mode == "1") { temp.push("Cash"); } else
                        if (obj.mode == "2") { temp.push("Cheque"); } else
                            if (obj.mode == "3") { temp.push("UPI / Online"); }
                if (obj.mode == "4" && obj.type == "0") { temp.push("Receivable (Sales)"); }


                temp.push(parseFloat(obj.amount).toFixed(2));
                if (obj.mode != "0" && obj.mode != "4") {
                    temp.push(parseFloat(obj.utilized_amount).toFixed(2));
                    temp.push(parseFloat(obj.amount - obj.utilized_amount).toFixed(2));
                }
                rows.push(temp);
            }
            console.log(rows);
            commonMixin.methods.createCSV(rows, "Transactions.csv");
        },
    },
    async mounted() {
        this.objs = await (await commonMixin.methods.callGet("transaction", "")).obj;
    },
    computed: {
        filteredObjs: function () {
            return this.objs;
            //As of now don't filter
            // var tempObjs = [];
            // for (var i = 0; i < this.objs.length; i++) {
            //     if (this.objs[i].mode != 4) {
            //         tempObjs.push(this.objs[i]);
            //     }
            // }
            // return tempObjs;
        }
    }
}
</script>
    
<style>
.left {
    text-align: left;
}

.pointer {
    cursor: pointer;
}

tr a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    color: inherit;
    text-decoration: inherit;
}

.row {
    margin-bottom: 2em;
}</style>