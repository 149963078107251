const bootstrap = require('bootstrap')

export const commonMixin = {
    methods: {
        async callMethod(url, data, methodType) {
            on();
            const reqHeaders = {
                "Content-Type": "application/json"
            };

            if (localStorage.getItem("user-access-token") != null) {
                reqHeaders.Authorization = "Bearer " + localStorage.getItem("user-access-token");
            }

            const params = {
                method: methodType,
                headers: reqHeaders,
            }

            if (methodType != "GET") {
                params.body = JSON.stringify(data)
            }
            const res = await fetch(process.env.VUE_APP_BASE_URL + url, params)
                .then(async res => {
                    if (res.status == 401) {
                        show("Session timed out. You will be redirected.", true);
                        localStorage.clear();
                        setTimeout(function () {
                            window.location.href = "";
                        }, 3000);
                        throw new Error("HTTP status " + res.status);
                    }
                    return res;
                })
                .then(res => res.json())
                .catch(err => err);
            off();
            console.log(res);
            return await res;
        },

        getConvertedDate(timestamp) {
            if (timestamp == null) {
                return "";
            }
            return new Date(timestamp).toLocaleString("en-IN", { timeZone: "Asia/Calcutta" })
        },

        getJustDate(timestamp) {
            if (timestamp == null) {
                return "";
            }
            const today = timestamp;
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            const formattedToday = yyyy + '-' + mm + '-' + dd;
            return formattedToday;
        },

        async callPost(url, data) {
            return await this.callMethod(url, data, "POST");
        },

        async callGet(url, data) {
            return await this.callMethod(url, data, "GET");
        },

        async callDelete(url, data) {
            return await this.callMethod(url, data, "DELETE");
        },

        showError(message) {
            show(message, true);
        },

        showToast(message, isDanger = false) {
            show(message, isDanger);
        },

        createCSV(rows, fileName) {
            let csvContent = "data:text/csv;charset=utf-8,"
                + rows.map(e => { 
                    e = e.map(e => e == null ? "" : '"' + e + '"');
                    return e.join(",");
                })
                .join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", fileName);
            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
        }
    }
}

function on() {
    document.getElementById("overlay").style.display = "block";
}

function off() {
    document.getElementById("overlay").style.display = "none";
}

function show(message, isDanger = false) {
    document.getElementById("toast").classList.remove("bg-primary");
    document.getElementById("toast").classList.remove("bg-danger");

    if(isDanger) {
        document.getElementById("toast").classList.add("bg-danger");
    } else {
        document.getElementById("toast").classList.add("bg-primary");
    }
    
    const t = new bootstrap.Toast(document.getElementById("toast"));
    document.getElementById("toast-body").textContent = message;
    t.show();
}