<template>
    <br>
    <br>
    <h1>Item</h1>

    <div style="text-align: right; margin-bottom: 0.2em;">
        <button type="button" title="Add new" class="btn btn-light" @click="exportCSV()">
            <span class="bi bi-arrow-bar-up link-success"> Export</span></button>

        <button type="button" title="Add new" class="btn btn-light" @click="addNew()">
            <span class="bi bi-plus-square link-success"> Add New</span></button>
    </div>
    <div class="" style="overflow-x: auto">
        <table class="table table-striped table-hover text-nowrap pointer">
            <thead class="table-dark">
                <tr>
                    <th @click="sortByName('id')">ID</th>
                    <th @click="sortByName('name')" class="left">Name</th>
                    <th @click="sortByName('weight_in_grms')">Weight (in gm.)</th>
                    <th @click="sortByName('package_type')">Package</th>
                    <th @click="sortByName('name')">MRP</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="items.length == 0">
                    <td colspan="6">No data found.</td>
                </tr>
                <tr v-for="item in items" v-bind:key="item.id" @click="getInfo(item.id)">
                    <th scope="row">{{ item.id }}</th>
                    <td class="left">{{ item.name }}</td>
                    <td>{{ item.weight_in_grms }}</td>
                    <td>{{ item.package_type }}</td>
                    <td>₹ {{ item.mrp }}</td>
                    <td @click.stop>
                        <button type="button" title="Update item" class="btn btn-light" @click="editObj(item.id)"
                            style="margin-right: 0.2em;"><i class="bi bi-pencil link-warning"></i></button>
                        <button type="button" title="Remove item" class="btn btn-light" @click="deleteObj(item.id)"><i
                                class="bi bi-trash link-danger"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { commonMixin } from '@/js/common';

export default {
    name: 'app-item-main',
    data() {
        return {
            items: {},
            sortBy: "",
            sortByAsc: true,
        }
    },
    methods: {
        getInfo(id) {
            for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].id == id) {
                    this.$router.push({ name: 'ItemInfo', params: { "id": id, obj: JSON.stringify(this.items[i]) } })
                    break;
                }
            }
        },
        addNew() {
            this.$router.push({ name: 'ItemAdd' });
        },
        editObj(id) {
            this.$router.push({ name: 'ItemUpdate', params: { 'id': id } });
        },
        async deleteObj(id) {
            var res = confirm("Are you sure you want to delete?");
            if (res) {
                var data = await commonMixin.methods.callDelete('products/' + id, "");
                commonMixin.methods.showToast(data.message);
                this.items = await (await commonMixin.methods.callGet("products", "")).obj;
            }
        },
        exportCSV() {
            var rows = [
                ["id", "name", "weight in grms", "package", "barcode", "rate", "mrp", "created_at", "updated_at"]
            ];
            for (var i = 0; i < this.items.length; i++) {
                var item = this.items[i];
                var temp = [];
                temp.push(item.id);
                temp.push(item.name);
                temp.push(item.weight_in_grms);
                temp.push(item.package_type);
                temp.push(item.barcode);
                temp.push(parseFloat(item.rate).toFixed(2));
                temp.push(parseFloat(item.mrp).toFixed(2));
                temp.push(item.created_at);
                temp.push(item.updated_at);
                rows.push(temp);
            }
            console.log(rows);
            commonMixin.methods.createCSV(rows, "Items.csv");
        },

        sortByName(sortBy) {
            if(this.sortBy == sortBy) {
                this.sortByAsc = !this.sortByAsc;
            } else {
                this.sortByAsc = true;
            }
            this.sortBy = sortBy;
            this.items.sort((a, b) => {
                if(a[sortBy] == null || a[sortBy] == "") {
                    return this.sortByAsc ? 1 : -1;
                }
                if(b[sortBy] == null || b[sortBy] == "") {
                    return this.sortByAsc ? -1 : 1;
                }
                if(this.sortByAsc) {
                    return (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0);
                } else {
                    return (b[sortBy] > a[sortBy]) ? 1 : ((a[sortBy] > b[sortBy]) ? -1 : 0);
                }
            })
        }
    },

    async mounted() {
        this.items = await (await commonMixin.methods.callGet("products", "")).obj;
    }
}
</script>
    
<style>
.left {
    text-align: left;
}

.pointer {
    cursor: pointer;
}

tr a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    color: inherit;
    text-decoration: inherit;
}

.row {
    margin-bottom: 2em;
}
</style>