<template>
Dashboard
</template>


<script>

export default {
    name: "app-dashboard",
    data() {
        return {
            appName: process.env.VUE_APP_NAME,
        };
    },
    mounted() {
        if (localStorage.getItem("user-access-token") == null) {
            this.$router.push({ name: "Login" });
        }
    },
    methods: {
        
    },
}
</script>